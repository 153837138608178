import ButtonDX from "../components/controls/buttondx";
import GridDX from "../components/layout/griddx";
import TypographyDX from "../components/layout/typographydx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UnitsAppBar from "../components/business/appbar/units_appbar";
import BoxDX from "../components/layout/boxdx";
import { useNavigate, useParams } from "react-router-dom";

const AcceptQuotation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const onAccept = () => setAccepted(true);
  const onDecline = () => navigate("/requestaquote");

  return (
    <>
      {/* {isLoading && <LoadingOverlay />} */}
      <UnitsAppBar forQuote />
      <GridDX
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          height: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <GridDX
          xs={10}
          sm={7}
          md={5}
          container
          columnSpacing={1}
          rowSpacing={2}
          sx={{
            my: 2,
          }}
        >
          {!accepted && (
            <>
              <GridDX item xs={12} sx={{ zIndex: 1, margin: 0, padding: 0 }}>
                <TypographyDX
                  variant="h4"
                  sx={{
                    mb: 2,
                    width: "100%",
                  }}
                >
                  {t("Accept Quotation")}
                </TypographyDX>
              </GridDX>
              <GridDX
                xs={12}
                container
                columnSpacing={1}
                rowSpacing={2}
                flexDirection="column"
                sx={{
                  backgroundColor: "#fff",
                  zIndex: 1,
                  p: 5,
                  pb: 1,
                  mt: 0.5,
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "10px",
                }}
              >
                <TypographyDX>{t("Dear Customer,")}</TypographyDX>
                <TypographyDX sx={{ mx: 3, mt: 1 }}>
                  {t("accept quotation message")}
                </TypographyDX>
                <BoxDX display="flex" flexDirection="row" sx={{ mx: 3, mt: 1 }}>
                  <TypographyDX fontWeight={"bold"}>
                    {`${t("Invoice No")}:`}
                  </TypographyDX>
                  <TypographyDX>{id}</TypographyDX>
                </BoxDX>
                <BoxDX display="flex" flexDirection="row" sx={{ mx: 3 }}>
                  <TypographyDX fontWeight={"bold"}>
                    {`${t("Customer Name")}:`}
                  </TypographyDX>
                  <TypographyDX>John</TypographyDX>
                </BoxDX>
                <BoxDX display="flex" flexDirection="row" sx={{ mx: 3 }}>
                  <TypographyDX fontWeight={"bold"}>
                    {`${t("Company Name")}:`}
                  </TypographyDX>
                  <TypographyDX>XYZ</TypographyDX>
                </BoxDX>
                <BoxDX display="flex" flexDirection="row" sx={{ mx: 3 }}>
                  <TypographyDX fontWeight={"bold"}>
                    {`${t("Total Amount")}:`}
                  </TypographyDX>
                  <TypographyDX>20,000</TypographyDX>
                </BoxDX>
                <BoxDX
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  sx={{ mt: 5 }}
                >
                  <ButtonDX
                    variant="outlined"
                    sx={{ mx: 1 }}
                    onClick={onDecline}
                  >
                    {t("Decline")}
                  </ButtonDX>
                  <ButtonDX onClick={onAccept}>{t("Accept")}</ButtonDX>
                </BoxDX>
              </GridDX>
            </>
          )}
          {accepted && (
            <GridDX
              item
              xs={12}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ zIndex: 1, margin: 0, padding: 0 }}
            >
              <TypographyDX
                variant="h4"
                sx={{
                  mb: 2,
                }}
                textAlign="center"
              >
                {t("Quotation Accepted")}
              </TypographyDX>
              <TypographyDX>{t("quotation accepted message")}</TypographyDX>
              <TypographyDX>{t("Thank You!")}</TypographyDX>
            </GridDX>
          )}
        </GridDX>
        <div
          style={{
            position: "absolute",
            height: 800,
            width: 800,
            backgroundColor: "#F7F7F7",
            borderRadius: "100%",
            zIndex: -1,
            top: 100,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: 700,
            width: 700,
            backgroundColor: "#F2F2F2",
            borderRadius: "100%",
            zIndex: -1,
            top: 150,
          }}
        ></div>
      </GridDX>
    </>
  );
};

export default AcceptQuotation;
