import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import { useOutletContext } from "react-router-dom";
import { DATE_FORMAT, outboundRequestStatusColor,  } from "../shared/globals";
import moment from "moment";
import TypeTranslator from "../shared/typetranslator";
import { useAuthContext } from "../context/authcontext";
import useOutboundRequestService from "../shared/services/outboundrequestservice";
import OutboundRequestForm from "../components/units_forms/outboundrequests/outboundrequestform";

const OutboundRequests = () => {
  const { userData } = useAuthContext();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { getOutboundRequests,archiveOutboundRequest} =
    useOutboundRequestService();
  const { setInfo, setError } = useNotificationContext();
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [outboundRequestId, setOutboundRequestId] = useState(null);
  const {
    getOutboundDeliveryTypeValue,
    getOutboundRequestStatusValue,
    outboundRequestStatuses,
  } = TypeTranslator();

  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: `${t("Request Date")}`,
      flex: 1,
    },
    {
      field: "outboundDeliveryTypeId",
      headerName: `${t("Outbound Delivery Type")}`,
      flex: 1,
    },
    {
      field: "scheduleDate",
      headerName: `${t("Schedule Date")}`,
      flex: 1,
    },
    {
      field: "pocName",
      headerName: `${t("POC Name")}`,
      flex: 1,
    },
    {
      field: "pocPhone",
      headerName: `${t("POC Phone")}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                outboundRequestStatusColor.light[
                  outboundRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ] || "white",
              color: `${
                outboundRequestStatusColor.regular[
                  outboundRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];
  // Check if userType is 'S'
  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    columns.splice(1, 0, customerNameColumn);
  }

  const getData = () => {
    setIsLoading(true);
    getOutboundRequests()
      .then((response: any) => {
        const updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.requestDate).format(DATE_FORMAT),
          scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
          outboundDeliveryTypeId: getOutboundDeliveryTypeValue(
            request.outboundDeliveryTypeId
          ),
          status: getOutboundRequestStatusValue(request.status),
        }));
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveOutboundRequest(id)
      .then((response: any) => {
        setInfo(t("Outbound request archived successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onEdit = (data: any) => {
    setOutboundRequestId(data.outboundRequestId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setOutboundRequestId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        outboundDeliveryTypeId,
        scheduleDate,
        status,
        pocPhone,
        pocName,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          getOutboundDeliveryTypeValue(outboundDeliveryTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
          // status.toLowerCase().includes(value.toLowerCase()) ||
          pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
          pocName.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();
    return () => {
      setShowTitle(true);
      setAddRecordfn(() => {});
    };
    //  whenever items change, recalculate the total amount
  }, [i18n.language]);

  return (
    <>
      {showForm ? (
        <OutboundRequestForm
          requestId={outboundRequestId}
          rows={rows}
          setRows={setRows}
          handleClose={onClose}
        />
      ) : (
        <ListPageDX
        listTitle={t("Outbound Requests")}
        name={t("Outbound Request")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.outboundRequestId}
          isLoading={isLoading}
          // toCreate={toCreate}
          onEdit={onEdit}
          editLabel={t("Edit")}
          onDelete={onDelete}
          deleteLabel={t("Archive")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={true}
          filterByDate={filterByDate}
        />
      )}
    </>
  );
};

export default OutboundRequests;
