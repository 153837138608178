import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useState, useCallback, memo } from 'react';
import BoxDX from '../layout/boxdx';
import TextFieldDX from '../controls/textfielddx';
import { IconButton, InputAdornment } from '@mui/material';
import { MyLocation, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const riyadhCoord = {
  lat: 24.7136,
  lng: 46.6753
};

const AddressPicker = (props: any) => {
  const { t } = useTranslation();
  const { location, setLocation, onAddressChange, emptyAddressError } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCzboJTvYefpqGawPVzz8CFuij_TGakKzQ" //AIzaSyAIhidZwbkqfixvhurM4lvepYTTzJY_iLs  //AIzaSyA3FzKFHiA7bUcmOaubinG6wqCZt8Dw7Yk
  });
  const [map, setMap] = useState<any>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    onAddressChange(true);
    setLocation({
      ...location,
      [name]: value,
    });
  };

  const onLoad = useCallback(function callback(map: any) {
    setMap(map)
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null)
  }, []);

  const findPlaces = async () => {
    const { Place } = await window.google.maps.importLibrary("places") as google.maps.PlacesLibrary;
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const request = {
      textQuery: 'panaroma mall',
      fields: ['displayName', 'location', 'businessStatus'],
      locationBias: riyadhCoord,
      isOpenNow: true,
      language: 'en-US',
      useStrictTypeFiltering: false,
    };

    //@ts-ignore
    const { places } = await Place.searchByText(request);
    console.log(places);
    if (places.length) {

//         const { LatLngBounds } = await google.maps.importLibrary("core") as google.maps.CoreLibrary;
//         const bounds = new LatLngBounds();

//         // Loop through and get all the results.
//         places.forEach((place: any) => {
//             const markerView = new AdvancedMarkerElement({
//                 map,
//                 position: place.location,
//                 title: place.displayName,
//             });

//             bounds.extend(place.location);
//             console.log(place);
//         });

//         map.fitBounds(bounds);

    } else {
        console.log('No results');
    }
};

  return(
    <BoxDX
      display = "flex"
      flexDirection = "column"
      width = "100%"
    >
      <TextFieldDX
        label={t("Street Address")}
        name="streetAddress"
        sx={{mb: 2}}
        value={location.streetAddress}
        onChange={handleInputChange}
        error={emptyAddressError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="start"
                sx = {{
                  color: "#6800F4"
                }}
                onClick={()=>{
                  navigator?.geolocation.getCurrentPosition(
                    ({ coords: { latitude: lat, longitude: lng } }) => {
                      onAddressChange(true);
                      setLocation(
                        {
                          ...location,
                          lat: lat,
                          lng: lng
                        }
                      );
                    }
                  );
                }}
              >
                <MyLocation/>
              </IconButton>
              <IconButton
                edge="end"
                sx = {{
                  color: "#6800F4"
                }}
                onClick={findPlaces}
              >
                <Search/>
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: location.lat,
            lng: location.lng
          }}
          zoom={16}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker
            position={location}
            draggable={true}
            onDragEnd={(e) => {
              onAddressChange(true);
              setLocation(
                {
                  ...location,
                  lat: e?.latLng?.lat(),
                  lng: e?.latLng?.lng()
                }
              );
            }}
          />
        </GoogleMap>
      ) : <></>}
    </BoxDX>
  ); 
};

export default memo(AddressPicker)