import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit } from "@mui/icons-material";

import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";

import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import TypographyDX from "../../layout/typographydx";

const WarehouseForm = (props: any) => {
  const areaColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { handleClose } = props;

  const navigate = useNavigate();

  const { getCities } = useCityService();
  const { setInfo, setError } = useNotificationContext();

  const [warehouseData, setWarehouseData] = useState<any>({
    warehouseId: 1,
    name: "Warehouse 1",
    streetAddress: "Somewhere",
    cityName: "Riyadh",
    warehouseAreas: [
      {
        warehouseAreaId: 1,
        name: "Area 1",
      },
    ],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<any>([]);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setWarehouseData({
      ...warehouseData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    // if (!warehouseData.customerName.trim().length)
    //   newErrors["customerName"] = t("Customer Name is required");
    // if (!warehouseData.customerAddress)
    //   newErrors["customerAddress"] = t("Customer Address is required");
    // if (!warehouseData.dueDate)
    //   newErrors["dueDate"] = t("Due Date is required");
    // if (!warehouseData.customerCityId)
    //   newErrors["customerCityId"] = t("Customer City is required");
    // if (!warehouseData.warehouseId)
    //   newErrors["warehouseId"] = t("Warehouse is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...warehouseData,
      };

      updatedData = {
        ...updatedData,
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      // const operation = quotationId
      //   ? updateQuotation(updatedData)
      //   : addQuotation(updatedData);
      // operation
      //   .then(() => {
      //     quotationId
      //       ? setInfo(t("Quotation updated successfully"))
      //       : setInfo(t("Quotation added successfully"));
      //     handleClose(true);
      //   })
      //   .catch((error) => {
      //     setError(error);
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  const onEdit = (data: any) => {
    navigate("/warehouseareadetails");
  };

  const onArchive = (data: any) => {};

  const getDropDownData = () => {
    // get data from the server
    const p1 = getCities();
    Promise.all([p1]).then(([cities]) => {
      setCities(
        cities.map((city: any) => ({ text: city.cityName, value: city.cityId }))
      );
    });
  };

  const getData = () => {};

  useEffect(() => {
    getData();
    getDropDownData();
  }, []);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Warehouse Details")}
        onSaveClick={onSave}
        onYesClick={handleClose}
        quotationStatus={warehouseData.status}
        showSaveAndSubmit={warehouseData.status == 1 && true}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Name")}
            name="name"
            value={warehouseData.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Number")}
            name="number"
            value={warehouseData.number}
            onChange={handleInputChange}
            error={errors["number"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Address")}
            name="streetAddress"
            value={warehouseData.streetAddress}
            onChange={handleInputChange}
            error={errors["streetAddress"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("City")}
            items={cities}
            name="cityId"
            value={warehouseData.cityId}
            onChange={handleInputChange}
            error={errors["cityId"]}
          />
        </GridDX>
      </AddEditEmbeddedModal>
      <ItemBox>
        <TypographyDX variant="h5" sx={{ mb: 1 }}>
          {t("Areas")}
        </TypographyDX>
        <DataGridDX
          getRowId={(row: any) => row.warehouseAreaId}
          rows={warehouseData.warehouseAreas}
          columns={areaColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
          // customActions={customActions}
          // customFilters={customFilters}
          // filterByStatus={filterByStatus}
          // filterByType={filterByType}
          // hideColumnsOption={hideColumnsOption}
          // rowsPerPage={rowsPerPage}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseForm;
