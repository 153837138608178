import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import { useOutletContext } from "react-router-dom";
import { DATE_FORMAT, inboundRequestStatusColor } from "../shared/globals";
import InboundRequestForm from "../components/units_forms/inboundrequests/inboundrequestform";
import useInboundRequestService from "../shared/services/inboundrequestservice";
import moment from "moment";
import TypeTranslator from "../shared/typetranslator";
import { useAuthContext } from "../context/authcontext";

const InboundRequests = () => {
  const { userData } = useAuthContext();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { getInboundRequests, archiveInboundRequest } =
    useInboundRequestService();
  const { setInfo, setError } = useNotificationContext();
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [inboundRequestId, setInboundRequestId] = useState(null);
  const {
    getInboundDeliveryTypeValue,
    getInboundRequestStatusValue,
    inboundRequestStatuses,
  } = TypeTranslator();

  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: `${t("Request Date")}`,
      flex: 1,
    },
    {
      field: "inboundDeliveryTypeId",
      headerName: `${t("Inbound Delivery Type")}`,
      flex: 1,
    },
    {
      field: "scheduleDate",
      headerName: `${t("Schedule Date")}`,
      flex: 1,
    },
    {
      field: "pocName",
      headerName: `${t("POC Name")}`,
      flex: 1,
    },
    {
      field: "pocPhone",
      headerName: `${t("POC Phone")}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                inboundRequestStatusColor.light[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ] || "white",
              color: `${
                inboundRequestStatusColor.regular[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
      // renderCell: (params: any) => {
      //   return (
      //     <div
      //       style={{
      //         backgroundColor: inboundRequestStatusColor.light[params.value] || "white",
      //         color: `${inboundRequestStatusColor.regular[params.value]}`,
      //         padding: "5px",
      //         margin: "5px",
      //         borderRadius: "4px",
      //         width: 90,
      //         textAlign: "center",
      //       }}
      //     >
      //       {getInboundRequestStatusValue(params.value)}
      //     </div>
      //   );
      // },
    },
  ];
  // Check if userType is 'S'
  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    columns.splice(1, 0, customerNameColumn);
  }

  const getData = () => {
    setIsLoading(true);
    getInboundRequests()
      .then((response: any) => {
        const updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.requestDate).format(DATE_FORMAT),
          scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
          inboundDeliveryTypeId: getInboundDeliveryTypeValue(
            request.inboundDeliveryTypeId
          ),
          status: getInboundRequestStatusValue(request.status),
        }));
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDelete = (id: number) => {
    setRows(rows.filter((row: any) => row.inbound_request_id !== id));
    setInfo(t("Inbound request deleted successfully"));

    setIsLoading(true);
    archiveInboundRequest(id)
      .then((response: any) => {
        setInfo(t("Inbound request archived successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onEdit = (data: any) => {
    setInboundRequestId(data.inboundRequestId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setInboundRequestId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        inboundDeliveryTypeId,
        scheduleDate,
        status,
        pocPhone,
        pocName,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          getInboundDeliveryTypeValue(inboundDeliveryTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
          // status.toLowerCase().includes(value.toLowerCase()) ||
          pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
          pocName.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();
    return () => {
      setShowTitle(true);
      setAddRecordfn(() => {});
    };
    //  whenever items change, recalculate the total amount
  }, [i18n.language]);

  return (
    <>
      {showForm ? (
        <InboundRequestForm
          requestId={inboundRequestId}
          rows={rows}
          setRows={setRows}
          handleClose={onClose}
        />
      ) : (
        <ListPageDX
          listTitle={t("Inbound Request")}
          name={t("Request")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.inboundRequestId}
          isLoading={isLoading}
          // toCreate={toCreate}
          onEdit={onEdit}
          editLabel={t("Edit")}
          onDelete={onDelete}
          deleteLabel={t("Archive")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={true}
          filterByDate={filterByDate}
        />
      )}
    </>
  );
};

export default InboundRequests;
