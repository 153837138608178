import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import QuotationsItems from "../../units_components/quotationitems";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import useQuotationService from "../../../shared/services/quotationservice";
import TypographyDX from "../../layout/typographydx";
import BoxDX from "../../layout/boxdx";
import {
  DATE_FORMAT,
  getTotalAmount,
  numberWithCommas,
} from "../../../shared/globals";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import CheckBoxDX from "../../controls/checkboxdx";
import useCityService from "../../../shared/services/cityservice";
import useWarehouseService from "../../../shared/services/warehouseservice";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import customerLogo from "../../../assets/customer_logo.png";
import TypeTranslator from "../../../shared/typetranslator";
import ItemBox from "../../units_components/itembox";

export const defaultQuotationValues = {
  requestForQuoteId: 1,
  warehouseId: 0,
  // quotationNumber: "",
  preparedByUserId: "",
  quotationDate: moment(),
  dueDate: moment().add(7, "days"),
  customerName: "",
  customerAddress: "",
  customerCityId: 0,
  companyName: "",
  customerVatNumber: "",
  grossAmount: 0,
  vatPercent: 0,
  vatAmount: 0,
  netAmount: 0,
  quotationItems: [],
};

export const handlePrintQuotation = (
  quotationData: any,
  getQuotationStatusValue: any
) => {
  // Create a new jsPDF instance
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Set document properties
  doc.setProperties({
    title: "Request For Quotation",
  });
  doc.setFont("Plus Jakarta Sans", "normal");
  doc.text("QUOTATION", 10, 20);
  doc.setFontSize(10);
  doc.setFont("arabic-font", "normal");
  doc.text("مؤسسة جودة الودائع للنقل والتخزين", 10, 25);

  doc.addImage(customerLogo, "JPEG", 160, 20, 45, 30); // Adjust the image dimensions and position as needed
  doc.setLineWidth(0.1);
  doc.line(10, 45, 200, 45); // 1st is x1, 2nd is y1, 3rd is x2, 4th is y2

  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("arabic-font", "bold");
  doc.text("Contact", 10, 50);
  doc.setTextColor(0);
  doc.setFontSize(10);
  doc.setFont("arabic-font", "normal");
  const headingX = doc.internal.pageSize.width - 10;
  doc.text(
    "شركة المنتج الخليجي المحدودة للتجارة - شركة المنتج الخليجي المحدودة للتجارة",
    10,
    55
  );

  // on right
  autoTable(doc, {
    body: [
      ["Status", getQuotationStatusValue(quotationData.status)],
      ["Invoice No", quotationData.quotationNumber],
      ["Invoice Date", moment(quotationData.quotationDate).format(DATE_FORMAT)],
      ["Due On", moment(quotationData.dueDate).format(DATE_FORMAT)],
      ["Due Amount", quotationData.netAmount],
    ],
    margin: { top: 65, left: 130 },
    theme: "plain",
    styles: {
      fontSize: 10,
      cellPadding: 2,
      minCellWidth: 0,
      cellWidth: 10,
    },
    headStyles: {},
    columnStyles: {
      0: { cellWidth: 40, halign: "right", fontStyle: "bold" },
      1: { cellWidth: 40, halign: "left" },
    },
  });

  const columns = [
    "#",
    "Product",
    "Qty",
    "Unit Price",
    "Dis.",
    "Total Before VAT",
  ];
  const rows = quotationData.quotationItems.map((item: any, index: number) => [
    index + 1,
    item.description,
    item.quantity,
    item.unitPrice,
    item.discountAmount,
    item.totalAmount,
  ]);

  autoTable(doc, {
    head: [columns],
    body: rows,
    startY: 115,
    theme: "plain",
    styles: {
      fontSize: 10,
      cellPadding: 2,
    },
    headStyles: {},
    columnStyles: {
      0: { cellWidth: 10 },
      1: { cellWidth: 70 },
      2: { cellWidth: 20 },
      3: { cellWidth: 30 },
      4: { cellWidth: 20 },
      5: { cellWidth: 40 },
    },
  });

  autoTable(doc, {
    body: [
      ["Total Before VAT", numberWithCommas(quotationData.grossAmount)],
      ["Total VAT", numberWithCommas(quotationData.vatAmount)],
      ["Total", numberWithCommas(quotationData.netAmount)],
    ],
    margin: { left: 130 },
    theme: "plain",
    styles: {
      fontSize: 10,
      cellPadding: 2,
    },
    headStyles: {},
    columnStyles: {
      0: { halign: "right" },
      1: { halign: "right" },
    },
  });
  doc.setFontSize(15);
  doc.setLanguage("ar");
  doc.setFont("arabic-font", "normal");

  autoTable(doc, {
    body: [
      [doc.processArabic(t("pdf_heading"))],
      [doc.processArabic(t("pdf_heading_1"))],
      [doc.processArabic(t("pdf_content_1_1"))],
      [doc.processArabic(t("pdf_content_1_2"))],
      [doc.processArabic(t("pdf_content_1_3"))],
      [doc.processArabic(t("pdf_heading_2"))],
      [doc.processArabic(t("pdf_content_2_1"))],
      [doc.processArabic(t("pdf_content_2_2"))],
      [doc.processArabic(t("pdf_content_2_3"))],
      [doc.processArabic(t("pdf_content_2_4"))],
      [doc.processArabic(t("pdf_heading_3"))],
      [doc.processArabic(t("pdf_content_3_1"))],
      [doc.processArabic(t("pdf_heading_4"))],
      [doc.processArabic(t("pdf_content_4_1"))],
      [doc.processArabic(t("pdf_heading_5"))],
      [doc.processArabic(t("pdf_content_5_1"))],
      [doc.processArabic(t("pdf_content_5_2"))],
      [doc.processArabic(t("pdf_heading_6"))],
      [doc.processArabic(t("pdf_content_6_1"))],
      [doc.processArabic(t("pdf_content_6_2"))],
      [doc.processArabic(t("pdf_content_6_3"))],
      [doc.processArabic(t("pdf_content_6_4"))],
    ],
    margin: { right: 10 },
    theme: "plain",
    styles: {
      fontSize: 10,
      cellPadding: 2,
      font: "arabic-font",
    },
    headStyles: {},
    columnStyles: {
      0: { halign: "right" },
    },
    // apply style to headings and content appropriately heading size 15 and content size 10 heading is bold
    // by using index of the row we can apply different styles to different rows there is no head just simple body with rows pdf_heading and so on
    didParseCell: function (data) {
      const i = data.row.index;
      if (
        i == 0 ||
        i == 1 ||
        i == 5 ||
        i == 10 ||
        i == 12 ||
        i == 14 ||
        i == 17
      ) {
        data.cell.styles.fontSize = 17;
      } else {
        data.cell.styles.fontSize = 12;
      }
    },
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Bank Account Information - معلومات الحساب البنكي",
          colSpan: 4,
          styles: { halign: "center" },
        },
      ],
      [
        "1234567899090",
        "رقم حساب \n Account Number",
        "بنك الحساب الذي سيتم إيداع الأموال فيه",
        "أسم المستفيد \nBeneficiary Name",
      ],
      [
        "324324324324324234",
        "رقم الحساب المصرفي الدولي \n IBAN",
        "بنك الراجحي \n Rajhi bank",
        "بنك \nBank",
      ],
    ],
    theme: "plain",
    styles: {
      fontSize: 10,
      cellPadding: 2,
      font: "arabic-font",
      lineWidth: 0.3, // Border width
      lineColor: [0, 0, 0], // Border color (black in this example)
    },
    headStyles: {},
    columnStyles: {
      0: { halign: "center", valign: "middle", cellWidth: 50 }, // Adjust width as needed
      1: { halign: "center", valign: "middle", cellWidth: 55 },
      2: { halign: "center", valign: "middle", cellWidth: 43 },
      3: { halign: "center", valign: "middle", cellWidth: 35 },
    },
  });

  //   // To open the PDF in a new tab:
  // doc.output("dataurlnewwindow");

  // // To save the PDF:
  doc.save(`${quotationData.quotationNumber}.pdf`);
};
const QuotationForm = (props: any) => {
  const { rows, quotationId, leadData, handleClose, subscriptionPackages } =
    props;
  const { getQuotationById, addQuotation, updateQuotation } =
    useQuotationService();

  const { getCities } = useCityService();
  const { getWarehouses } = useWarehouseService();
  const { getQuotationStatusValue, getLeadRequestStatusValue } =
    TypeTranslator();

  // Refs for input fields
  const customerNameRef = useRef<HTMLInputElement>(null);
  const customerAddressRef = useRef<HTMLInputElement>(null);

  const [quotationData, setQuotationData] = useState<any>(
    defaultQuotationValues
  );
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setInfo, setError } = useNotificationContext();
  const [cities, setCities] = useState<any>([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setQuotationData({
      ...quotationData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setQuotationData({
      ...quotationData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!quotationData.customerName.trim().length)
      newErrors["customerName"] = t("Customer Name is required");
    if (!quotationData.customerAddress)
      newErrors["customerAddress"] = t("Customer Address is required");
    if (!quotationData.dueDate)
      newErrors["dueDate"] = t("Due Date is required");
    if (!quotationData.customerCityId)
      newErrors["customerCityId"] = t("Customer City is required");
    if (!quotationData.warehouseId)
      newErrors["warehouseId"] = t("Warehouse is required");
    if (!quotationData.customerVatNumber)
      newErrors["customerVatNumber"] = t("Customer VAT Number is required");

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      if (newErrors["customerName"]) {
        customerNameRef.current?.focus();
      } else if (newErrors["customerAddress"]) {
        customerAddressRef.current?.focus();
      }
      // Focus on more fields if needed

      return false; // Form is not valid
    }

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...quotationData,
        status: status == 2 ? 2 : 1,
      };
      // remove item_id from quotationItems to send to the server
      updatedData = {
        ...updatedData,
        quotationItems: quotationData.quotationItems.map((item: any) => {
          const { item_id, ...rest } = item;
          return rest;
        }),
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      updatedData = {
        ...updatedData,
        quotationItems: updatedData.quotationItems.filter(
          (item: any) => item.quotationItemId || !item.isDeleted
        ),
      };

      const operation = quotationId
        ? updateQuotation(updatedData)
        : addQuotation(updatedData);
      operation
        .then(() => {
          quotationId
            ? setInfo(t("Quotation updated successfully"))
            : setInfo(t("Quotation added successfully"));
          handleClose(true);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getDropDownData = () => {
    // get data from the server
    const p1 = getCities();
    const p2 = getWarehouses();
    Promise.all([p1, p2]).then(([cities, warehouses]) => {
      setCities(
        cities.map((city: any) => ({ text: city.cityName, value: city.cityId }))
      );
      setWarehouses(
        warehouses.map((warehouse: any) => ({
          text: warehouse.name,
          value: warehouse.warehouseId,
        }))
      );
    });
  };
  const getData: any = () => {
    if (leadData) {
      setQuotationData({
        ...defaultQuotationValues,
        customerName: leadData.fullname,
        customerAddress: leadData.address,
        customerCityId: leadData.city,
        companyName: leadData.companyName,
        customerVatNumber: leadData.vatNumber,
        requestForQuoteId: leadData.requestForQuoteId,
      });
    } else if (quotationId) {
      setIsLoading(true);
      getQuotationById(quotationId)
        .then((response) => {
          setQuotationData({
            ...response,
            quotationItems: response.quotationItems.map(
              (item: any, index: number) => ({
                ...item,
                item_id: index + 1 * 1000,
              })
            ),
          });
          setIsFormEditable(response.status == 1);
        })

        .catch((error) => {
          setError(t("Error getting quotation"));
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    setQuotationData((prevData: any) => ({
      ...prevData,
      ...getTotalAmount(prevData, prevData.quotationItems),
    }));
  }, [quotationData.vatPercent]);
  useEffect(() => {
    getData();
    getDropDownData();
  }, []);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Quotation Details")}
        onSaveClick={onSave}
        onYesClick={handleClose}
        quotationStatus={quotationData.status}
        showSaveAndSubmit={
          (quotationData.status == 1 || !quotationData.status) && true
        }
        isPrintable={true}
        handleGeneratePDF={() =>
          handlePrintQuotation(quotationData, getQuotationStatusValue)
        }
      >
        {quotationId && (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled
              label={t("Quotation Number")}
              name="quotationNumber"
              value={quotationData.quotationNumber}
              onChange={handleInputChange}
              error={errors["quotationNumber"]}
            />
          </GridDX>
        )}
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled
            label={t("Quotation Date")}
            name="quotationDate"
            value={moment(quotationData.quotationDate)}
            onChange={handleInputChange}
            error={errors["quotationDate"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled={!isFormEditable}
            label={t("Due Date")}
            name="dueDate"
            value={moment(quotationData.dueDate)}
            minDate={quotationData.quotationDate}
            onChange={(value: any) => handleDateChange(value, "dueDate")}
            error={errors["dueDate"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer Name")}
            name="customerName"
            value={quotationData.customerName}
            onChange={handleInputChange}
            error={errors["customerName"]}
            inputRef={customerNameRef} // Assign the ref
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer Address")}
            name="customerAddress"
            value={quotationData.customerAddress}
            onChange={handleInputChange}
            error={errors["customerAddress"]}
            inputRef={customerAddressRef} // Assign the ref
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Customer City")}
            items={cities}
            name="customerCityId"
            value={quotationData.customerCityId}
            onChange={handleInputChange}
            error={errors["customerCityId"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Company Name")}
            name="companyName"
            value={quotationData.companyName}
            onChange={handleInputChange}
            error={errors["companyName"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer VAT Number")}
            name="customerVatNumber"
            value={quotationData.customerVatNumber}
            onChange={handleInputChange}
            error={errors["customerVatNumber"]}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Warehouse")}
            items={warehouses}
            name="warehouseId"
            value={quotationData.warehouseId}
            onChange={handleInputChange}
            error={errors["warehouseId"]}
          />
        </GridDX>
        <GridDX item xs={8}>
          <CheckBoxDX
            disabled={!isFormEditable}
            label={t("VAT Included (15 %)")}
            name="vatPercent"
            checked={quotationData.vatPercent !== 0}
            onChange={(e: any) => {
              e.target.checked
                ? setQuotationData({ ...quotationData, vatPercent: 15 })
                : setQuotationData({ ...quotationData, vatPercent: 0 });
            }}
          />
        </GridDX>
        <GridDX item xs={4}>
          <GridDX container spacing={1}>
            <GridDX
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <TypographyDX variant="subtitle1">Gross Amount :</TypographyDX>
              <TypographyDX variant="subtitle1" fontWeight="bold">
                {numberWithCommas(quotationData.grossAmount.toFixed(2))}
              </TypographyDX>
            </GridDX>

            <GridDX
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <TypographyDX variant="subtitle1">Total Amount :</TypographyDX>
              <TypographyDX variant="subtitle1" fontWeight="bold">
                {numberWithCommas(quotationData.netAmount.toFixed(2))}
              </TypographyDX>
            </GridDX>
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>

      <ItemBox>
        {quotationData.quotationItems && (
          <QuotationsItems
            quotationData={quotationData}
            setQuotationData={setQuotationData}
            getData={getData}
            setIsChanged={setIsChanged}
            isFormEditable={isFormEditable}
            subscriptionPackages={subscriptionPackages}
          />
        )}
      </ItemBox>
    </BoxDX>
  );
};

export default QuotationForm;
