import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import GridDX from "../../../components/layout/griddx";
import TextFieldDX from "../../../components/controls/textfielddx";
import ButtonDX from "../../../components/controls/buttondx";
import LoginBox from "../../../components/layout/loginbox";
import LoadingOverlay from "../../../components/layout/loadingoverlay";
import { useNotificationContext } from "../../../context/notificationcontext";
import { forgetPassword } from "../../../shared/services/authservice";

const GetEmail = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();

  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [toolInfo, setToolInfo] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    validate(email);
  }, [email]);

  const handleChange = (event: any) => {
    setEmail(event.target.value);
  };

  const requestLink = async (event: any) => {
    event.preventDefault();
    const request = { email: email };

    setIsLoading(true);
    forgetPassword(request)
      .then((response) => {
        setLinkSent(true);
      })
      .catch((err) => setError("User Not Found"))
      .finally(() => setIsLoading(false));
  };

  function validate(email: any) {
    const validEmail = /^\S+@\S+\.\S+$/;
    if (!validEmail.test(email)) {
      setOpen(true);
      setToolInfo(t("Enter Valid Email"));
      return false;
    } else {
      setOpen(false);
      setToolInfo("");
      setErrors("");
      return true;
    }
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <LoginBox title={t("Forgot Password")}>
        <GridDX container rowSpacing={4}>
          {!linkSent ? (
            <>
              <GridDX item xs={12} justifyContent="center">
                <Tooltip title={toolInfo} arrow open={open}>
                  <TextFieldDX
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    name="email"
                    value={email}
                    onChange={handleChange}
                    label={t("Email Address")}
                    error={errors}
                  />
                </Tooltip>
              </GridDX>
              <GridDX item xs={12} justifyContent="center">
                <ButtonDX
                  fullWidth
                  disabled={open ? true : false}
                  startIcon={
                    <ArrowRightAltIcon
                      sx={{
                        transform:
                          i18n.dir() === "rtl" ? "rotate(180deg)" : "inherit",
                      }}
                    />
                  }
                  onClick={requestLink}
                >
                  {t("Continue")}
                </ButtonDX>
              </GridDX>
            </>
          ) : (
            <GridDX item xs={12} justifyContent="center">
              <Typography>
                {t("Link has been sent, check your inbox!")}
              </Typography>
            </GridDX>
          )}
          <GridDX item xs={12} justifyContent="center">
            <Typography color="#625B71">{t("or")}</Typography>
          </GridDX>
          <GridDX item xs={12} justifyContent="center">
            <ButtonDX
              fullWidth
              variant="text"
              color="secondary"
              size="small"
              onClick={() => navigate("/")}
            >
              {t("Go Back to Sign In Screen")}
            </ButtonDX>
          </GridDX>
        </GridDX>
      </LoginBox>
    </>
  );
};

export default GetEmail;
