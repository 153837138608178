import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useSubscriptionPackageService from "../../shared/services/subscriptionpackageservice";
import PackageForm from "../../components/units_forms/packageform";

const Packages = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getSubscriptionPackages, archiveSubscriptionPackage } = useSubscriptionPackageService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [subPackage, setSubPackage] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 150
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
    },
    {
      field: "price",
      headerName: `${t("Price")}`,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "packageDays",
      headerName: `${t("Package Days")}`,
      headerAlign: "center",
      align: "center",
      width: 130
    },
    {
      field: "bufferDays",
      headerName: `${t("Buffer Days")}`,
      headerAlign: "center",
      align: "center",
      width: 130
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getSubscriptionPackages()
      .then((data) => {
        setDataFromApi(data);
        setRows(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (data: any) => {
    setSubPackage(data);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveSubscriptionPackage(id)
      .then(() => {
        setInfo(t("Package deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setSubPackage(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.description.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  return (
    <>
      {showForm ? (
        <PackageForm
          packageData={subPackage}
          handleClose={onClose}
        />
      ) : (
        <ListPageDX
          listTitle={t("Packages")}
          name={t("Packages")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.subscriptionPackageId}
          isLoading={isLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          editLabel={t("Preview")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={false}
          showSelect={false}
        />
      )}
    </>
  );
};

export default Packages;
