import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useNotificationContext } from "../../context/notificationcontext";

const NotificationBarDX = () => {
  const { t } = useTranslation();
  const { error, info, setError, setInfo } = useNotificationContext();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (error || info) {
      if (info) setAlertMessage(`${t(info)}`);
      else if (error.message) setAlertMessage(`${t(error.message)}`);
      // to show errors generate by throw
      else setAlertMessage(`${t(error)}`);
      setShowAlert(true);
    }
  }, [error, info]);

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setError(null);
    setInfo(null);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={showAlert}
      autoHideDuration={5000}
      onClose={handleAlertClose}
    >
      <Alert onClose={handleAlertClose} severity={error ? "error" : "info"}>
        {alertMessage ?? "Unexpected error!"}
      </Alert>
    </Snackbar>
  );
};

export default NotificationBarDX;
