import { useEffect, useState } from "react";
import { t } from "i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import AddEditEmbeddedModal from "../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../context/notificationcontext";
import useSubscriptionPackageService from "../../shared/services/subscriptionpackageservice";

const PackageForm = (props: any) => {
  const { packageData, handleClose } = props;
  const { setInfo, setError } = useNotificationContext();
  const { addSubscriptionPackage, updateSubscriptionPackage } = useSubscriptionPackageService();
  const defaultData = {
    name: "",
    description: "",
    price: 0,
    packageDays: 0,
    bufferDays: 0
  };

  const [data, setData] = useState<any>(defaultData);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name)
      newErrors["name"] = "Name is required";
    if (data.price === 0)
      newErrors["price"] = "Price is required";
    if (data.packageDays === 0)
      newErrors["packageDays"] = "Package Days is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  
  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const operation = packageData ? updateSubscriptionPackage(data.subscriptionPackageId, data) : addSubscriptionPackage(data);
      operation
        .then(() => {
          setInfo(
            packageData ? t("Package updated successfully") : t("Package added successfully")
          );
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (packageData) setData(packageData);
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Package Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX
        container
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={8}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={data?.description}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Price")}
            name="price"
            value={data?.price}
            onChange={handleInputChange}
            type="number"
            error={errors["price"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Package Days")}
            name="packageDays"
            value={data?.packageDays}
            onChange={handleInputChange}
            type="number"
            error={errors["packageDays"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Buffer Days")}
            name="bufferDays"
            value={data?.bufferDays}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default PackageForm;
