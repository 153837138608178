import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

const ProgressBarDX = (props: any) => {
  const getColor = () => {
    if (props.value >= 80) return "#52C41A";
    else if (props.value >= 50) return "#FF8A36";
    else return "#F94839";
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: props.width ?? "100%",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "lightgray",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getColor(),
    },
  }));

  return <BorderLinearProgress variant="determinate" value={props.value} />;
};

export default ProgressBarDX;
