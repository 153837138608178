import { useTranslation } from "react-i18next";
import GridDX from "../../../layout/griddx";
import UtilizationBox from "../../../units_components/utilizationbox";
import GaugeBoxDX from "../../../layout/gaugeboxdx";
import SelectListDX from "../../../controls/selectlistdx";
import BoxDX from "../../../layout/boxdx";

const Utilization = () => {
  const { t } = useTranslation();

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={12} justifyContent="end">
        <BoxDX mt={1} width={350}>
          <SelectListDX
            label={t("Warehouse")}
            name="warehouseId"
            items={
              [
                {text: "Warehouse 1", value: 1},
                {text: "Warehouse 2", value: 2},
                {text: "Warehouse 3", value: 3},
                {text: "Warehouse 4", value: 4},
              ]
            }
          />
        </BoxDX>
      </GridDX>
      <GridDX item xs={9} minHeight={320}>
        <UtilizationBox/>
      </GridDX>
      <GridDX item xs={3} maxHeight={320}>
        <GaugeBoxDX title={t("Total Occupied")} value={67}/>
      </GridDX>
    </GridDX>
  );
};

export default Utilization;