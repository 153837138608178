import { t } from "i18next";
import BoxDX from "../../../layout/boxdx";
import DynamicTabs from "../../../layout/dynamictab";
import Utilization from "./utilization";
import GeneralOverview from "./generaloverview";
import GridDX from "../../../layout/griddx";
import PageTitle from "../../../layout/pagetitle";

const AdminDashboard = () => {
  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX
        item
        xs={6}
        sx={{
          marginBottom: 5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
      </GridDX>
      <BoxDX
        sx={{ width: "100%" }}
      >
        <DynamicTabs
          tabLabels={[t("General Overview"), t("Utilization")]}
          tabContents={[<GeneralOverview/>, <Utilization/>]}
        />
      </BoxDX>
    </GridDX>
  );
};

export default AdminDashboard;