import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { useTranslation } from "react-i18next";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./components/multilingual/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const LanguageAwareThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const theme = createDynamicTheme(!languageIsEn);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

root.render(
  <React.StrictMode>
    <LanguageAwareThemeProvider>
      <App />
    </LanguageAwareThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const createDynamicTheme = (isRtl: any) => {
  return createTheme(
    {
      palette: {
        primary: {
          main: "#6800F4",
        },
        secondary: {
          main: "#FF8A36",
        },
        success: {
          main: "#52C41A",
        },
        error: {
          main: "#F94839",
        },
      },
      direction: isRtl ? "rtl" : "ltr",
      typography: {
        fontFamily: "'Plus Jakarta Sans', sans-serif",
      },
    },
    arSA
  );
};
