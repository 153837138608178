import React, { createContext, useContext, useEffect, useState } from "react";
import { ReactNode } from "react";

const AuthContext = createContext<any | null>(null);
const useAuthContext = () => useContext(AuthContext);

interface AuxProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuxProps) => {
  const SESSION_NAME = "unitsAppSession";

  const [updatedData, setUpdatedData] = useState<any>(null);

  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            isLoggedIn: true,
          };
        case "SIGN_IN": {
          return {
            ...prevState,
            isLoggedIn: true,
            userData: action.userData,
            userToken: action.token,
          };
        }
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoggedIn: false,
            userData: null,
            userToken: null,
            discrepantData: null,
          };
        case "UPDATE_USER_DATA_AND_TOKEN": {
          return {
            ...prevState,
            userData: action.userData,
            userToken: action.userData.token,
          };
        }
      }
    },
    {
      isLoggedIn: false,
      userData: null,
      userToken: null,
    }
  );
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        const sessionInfoJSON = JSON.parse(sessionInfo);
        // decrypt the stored token to memory
        if (sessionInfoJSON.token) {
          // only decrypt if token is present
          signIn(
            sessionInfoJSON,
            sessionInfoJSON.token ? window.atob(sessionInfoJSON.token) : ""
          );
        }
      } catch (ex) {
        console.log("Error parsing session info:", ex);
      }
    }
    setAuthInitialized(true);
  }, []);

  const signIn = (data: any, token: string) => {
    const encodedToken = window.btoa(token);
    localStorage.setItem(
      SESSION_NAME,
      JSON.stringify({ ...data, token: encodedToken })
    );

    dispatch({
      type: "SIGN_IN",
      userData: data,
      token: token,
    });
  };

  const signOut = () => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);
    if (sessionInfo && sessionInfo.length > 0) {
      try {
        // Parse the session information
        const sessionInfoJSON = JSON.parse(sessionInfo);
        const { rememberMe } = sessionInfoJSON;
        rememberMe
          ? localStorage.setItem(
              SESSION_NAME,
              JSON.stringify({
                rememberMe: rememberMe,
                email: sessionInfoJSON.email,
                password: sessionInfoJSON.password,
              })
            )
          : localStorage.removeItem(SESSION_NAME);
      } catch (ex) {
        console.log("Error parsing or updating session info:", ex);
      }
    }

    dispatch({ type: "SIGN_OUT" });
  };

  const getUserDetails = async () => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        const sessionInfoJSON = JSON.parse(sessionInfo);
        return sessionInfoJSON;
      } catch (ex) {
        console.log("Error parsing session info:", ex);
        return {};
      }
    }
  };

  // FIX: requires fixing update state and then update storage
  const updateUserDetails = async (data: any) => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        let sessionInfoJSON = JSON.parse(sessionInfo);
        sessionInfoJSON = {
          ...sessionInfoJSON,
          ...data,
        };
        setUpdatedData(sessionInfoJSON);
        dispatch({
          type: "UPDATE_USER_DATA_AND_TOKEN",
          userData: sessionInfoJSON,
        });

        localStorage.setItem(SESSION_NAME, JSON.stringify(sessionInfoJSON));
      } catch (ex) {
        console.log("Error parsing session info:", ex);
        return {};
      }
    }
  };

  const removeUserData = async () => {
    localStorage.removeItem(SESSION_NAME);
  };

  const updateTokenInfo = async (tokenData: any) => {
    const encodedToken = window.btoa(tokenData.token);
    await updateUserDetails({ tokenInfo: tokenData, token: encodedToken });
  };

  return (
    <AuthContext.Provider
      value={{
        authInitialized,
        isLoggedIn: state.isLoggedIn,
        token: state.userData?.tokenInfo?.token,
        fullToken: state.userData?.tokenInfo,
        userData: state.userData,
        signIn,
        signOut,
        getUserDetails,
        updateUserDetails,
        removeUserData,
        updateTokenInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
