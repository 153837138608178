import GridDX from "../components/layout/griddx";
import PageTitle from "../components/layout/pagetitle";
import UnitsRangePicker from "../components/units_controls/units_rangepicker";

const Reports = () => {
  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX
        item
        xs={6}
        sx={{
          marginBottom: 5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <UnitsRangePicker />
      </GridDX>
    </GridDX>
  );
};

export default Reports;
