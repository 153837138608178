import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit, Save } from "@mui/icons-material";
import { useNotificationContext } from "../../../context/notificationcontext";

import BoxDX from "../../layout/boxdx";
import ItemBox from "../../units_components/itembox";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import SelectListDX from "../../controls/selectlistdx";
import DataGridDX from "../../layout/datagriddx";
import { InputAdornment } from "@mui/material";
import TypographyDX from "../../layout/typographydx";

const WarehouseRowDetails = (props: any) => {
  const bayColumns: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("Number")}`,
      flex: 1,
    },
    {
      field: "capacityInPallets",
      headerName: `${t("Capacity In Pallets")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Delete")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { handleClose } = props;

  const { setInfo, setError } = useNotificationContext();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState<any>({
    warehouseRowId: 1,
    warehouseAreaId: 1,
    number: "R1",
    rowModelTypeId: 1,
    capacityInCbm: 1,
    warehouseBays: [
      {
        warehouseBayId: 1,
        number: "B1",
        capacityInPallets: 10,
      },
    ],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const onCancel = () => {
    navigate(-1);
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setRowData({
      ...rowData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    // if (!warehouseData.customerName.trim().length)
    //   newErrors["customerName"] = t("Customer Name is required");
    // if (!warehouseData.customerAddress)
    //   newErrors["customerAddress"] = t("Customer Address is required");
    // if (!warehouseData.dueDate)
    //   newErrors["dueDate"] = t("Due Date is required");
    // if (!warehouseData.customerCityId)
    //   newErrors["customerCityId"] = t("Customer City is required");
    // if (!warehouseData.warehouseId)
    //   newErrors["warehouseId"] = t("Warehouse is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...rowData,
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      // const operation = quotationId
      //   ? updateQuotation(updatedData)
      //   : addQuotation(updatedData);
      // operation
      //   .then(() => {
      //     quotationId
      //       ? setInfo(t("Quotation updated successfully"))
      //       : setInfo(t("Quotation added successfully"));
      //     handleClose(true);
      //   })
      //   .catch((error) => {
      //     setError(error);
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  const onEdit = (data: any) => {
    navigate("/warehousebaydetails");
  };

  const onArchive = (data: any) => {};

  const getData = () => {};

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={t("Number")}
              name="number"
              value={rowData.number}
              onChange={handleInputChange}
              error={errors["number"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              label={t("Row Model")}
              name="rowModelTypeId"
              value={rowData.rowModelTypeId}
              items={[]}
              onChange={handleInputChange}
              error={errors["rowModelTypeId"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={t("Capacity")}
              name="capacityInCbm"
              value={rowData.capacityInCbm}
              onChange={handleInputChange}
              error={errors["capacityInCbm"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">CBM</InputAdornment>
                ),
              }}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      <ItemBox>
        <TypographyDX variant="h5" sx={{ mb: 1 }}>
          {t("Bays")}
        </TypographyDX>
        <DataGridDX
          getRowId={(row: any) => row.warehouseBayId}
          rows={rowData.warehouseBays}
          columns={bayColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
          // customActions={customActions}
          // customFilters={customFilters}
          // filterByStatus={filterByStatus}
          // filterByType={filterByType}
          // hideColumnsOption={hideColumnsOption}
          // rowsPerPage={rowsPerPage}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseRowDetails;
