import { apiCall } from "../api/api";
import useSecureAPI from "../hooks/usesecureapi";

const useLeadService = () => {
  const secureAPI = useSecureAPI();

  const getLeads = async () => {
    const response = await secureAPI.get("RequestForQuote");
    return response.data;
  };

  const getLeadById = async (id: number) => {
    const response = await secureAPI.get(`RequestForQuote/${id}`);
    return response.data;
  };

  const addLead = async (data: any) => {
    const response = await apiCall.post("RequestForQuote", data);
    return response.data;
  };

  const updateLead = async (data: any) => {
    const response = await secureAPI.put(`RequestForQuote`, data);
    return response.data;
  };

  const deleteLead = async (id: number) => {
    const response = await secureAPI.delete(`RequestForQuote/${id}`);
    return response.data;
  };
  const archiveLead = async (id: number) => {
    const response = await secureAPI.put(`RequestForQuote/archive/${id}`);
    return response.data;
  }
  return {
    getLeads,
    getLeadById,
    addLead,
    updateLead,
    deleteLead,
    archiveLead
  };
};

export default useLeadService;
