import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TypographyDX = (props: any) => {
  const { i18n } = useTranslation();
  return (
    <Typography
      {...props}
      style={{ display: "flex", ...props.style }}
      sx={{ direction: i18n.dir() === "rtl" ? "rtl" : "ltr", ...props.sx }}
    >
      {props.children}
    </Typography>
  );
};

export default TypographyDX;
