import useSecureAPI from "../hooks/usesecureapi";

const useInboundRequestService = () => {
  const secureAPI = useSecureAPI();

  const getInboundRequests = async () => {
    const response = await secureAPI.get("InboundRequest");
    return response.data;
  };

  const getInboundRequestById = async (id: number) => {
    const response = await secureAPI.get(`InboundRequest/${id}`);
    return response.data;
  };

  const addInboundRequest = async (data: any) => {
    const response = await secureAPI.post("InboundRequest", data);
    return response.data;
  };

  const updateInboundRequest = async (data: any) => {
    const response = await secureAPI.put(`InboundRequest`, data);
    return response.data;
  };

  const archiveInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/archive/${id}`);
    return response.data;
  };

  const acceptInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/accept/${id}`);
    return response.data;
  };
  const declineInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/decline/${id}`);
    return response.data;
  };
 
  const processInboundRequest = async (data: any) => {
    const response = await secureAPI.put(`InboundRequest/process`, data);
    return response.data;
  };


  const rejectModifications = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/modificationreject/${id}`);
    return response.data;
  }
  const approveModifications = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/modificationapprove/${id}`);
    return response.data;
  }
  return {
    getInboundRequests,
    getInboundRequestById,
    addInboundRequest,
    updateInboundRequest,
    archiveInboundRequest,
    processInboundRequest,
    acceptInboundRequest,
    declineInboundRequest,
    rejectModifications,
    approveModifications
  };
};

export default useInboundRequestService;
