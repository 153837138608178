import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuthContext } from "./context/authcontext";
import { NotificationContextProvider } from "./context/notificationcontext";
import { ConfigProvider, useConfigContext } from "./context/configcontext";

import LoadingOverlay from "./components/layout/loadingoverlay";
import ProtectedRoute from "./components/route/protectedroute";

import useRefreshToken from "./shared/hooks/userefreshtoken";

import AccountTemplate from "./templates/accounttemplate";
import MainTemplate from "./templates/maintemplate";
import ListPageTemplate from "./templates/listpagetemplate";

import LoginPage from "./pages/account/unitslogin";
import GetEmail from "./pages/account/forgetPassword/getEmail";
import ResetPassword from "./pages/account/forgetPassword/resetPassword";

import RequestQuoteForm from "./pages/requestaquote";
import Dashboard from "./pages/dashboard";
import Leads from "./pages/leads";
import Quotations from "./pages/quotations";
import OutboundRequests from "./pages/outboundrequests";
import InboundRequests from "./pages/inboundrequests";
import MovementRequests from "./pages/movementrequests";
import UnitsListPageTemplate from "./templates/units_listpagetemplate";
import UnitsMainTemplate from "./templates/units_maintemplate";
import { useTranslation } from "react-i18next";
import Users from "./pages/settings/users";
import WareHouse from "./pages/settings/warehouse";
import Packages from "./pages/settings/packages";
import SKU from "./pages/sku";
import moment from "moment";
import { DATE_FORMAT } from "./shared/globals";
import CustomerAddress from "./pages/customeraddress";
import WarehouseAreaDetails from "./components/pages/warehouse/warehouseareadetails";
import DetailsPageTemplate from "./templates/detailspagetemplate";
import WarehouseRowDetails from "./components/pages/warehouse/warehouserowdetails";
import WarehouseBayDetails from "./components/pages/warehouse/warehousebaydetails";
import Reports from "./pages/reports";
import AcceptQuotation from "./pages/acceptquotation";

function App() {
  return (
    <ConfigProvider>
      <NotificationContextProvider>
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>
      </NotificationContextProvider>
    </ConfigProvider>
  );
}

const ApplicationRoutes = () => {
  const { t, i18n } = useTranslation();
  const { authInitialized } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isLoggedIn, fullToken, userData, signOut } = useAuthContext();
  const refresh = useRefreshToken();
  const location = useLocation();

  const refreshToken = () => {
    if (userData.rememberMe) {
      refresh()
        .then(() => {
          console.log("refreshed");
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      signOut();
    }
  };

  useEffect(() => {
    if (authInitialized && !isLoggedIn) {
      setLoading(false);
    } else if (authInitialized && isLoggedIn && fullToken) {
      const currentDateTime = new Date().getTime();
      const expiryDateTime = new Date(fullToken.expiryDate).getTime();
      if (expiryDateTime < currentDateTime) {
        refreshToken();
      } else if (
        location.pathname === "/" ||
        location.pathname === "/forget-password"
      ) {
        setLoading(false);
        //to redirect to dashboard if user is logged in and tries to access login page
        navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [location.pathname, isLoggedIn, authInitialized]);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    // Check last selected language from localStorage
    const lastLanguage = localStorage.getItem("lastLanguage");
    if (lastLanguage) {
      // Set the language in i18n
      i18n.changeLanguage(lastLanguage);
    }
    setInitialLoading(false);
  }, []);

  if (initialLoading || loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<AccountTemplate />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forget-password" element={<GetEmail />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/requestaquote" element={<RequestQuoteForm />} />
          <Route path="/acceptaquote/:id" element={<AcceptQuotation />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <UnitsMainTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reports" element={<Reports />} />
          <Route element={<UnitsListPageTemplate />}>
            <Route path="/leads" element={<Leads />} />
            <Route path="/quotations" element={<Quotations />} />
            <Route path="/inboundrequests" element={<InboundRequests />} />
            <Route path="/outboundrequests" element={<OutboundRequests />} />
            <Route path="/movementrequests" element={<MovementRequests />} />
            <Route path="/users" element={<Users />} />
            <Route path="/warehouses" element={<WareHouse />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/sku" element={<SKU />} />
            <Route path="/customeraddress" element={<CustomerAddress />} />
          </Route>
          <Route element={<DetailsPageTemplate />}>
            <Route
              path="/warehouseareadetails"
              element={<WarehouseAreaDetails />}
            />
            <Route
              path="/warehouserowdetails"
              element={<WarehouseRowDetails />}
            />
            <Route
              path="/warehousebaydetails"
              element={<WarehouseBayDetails />}
            />
          </Route>
          {/* to redirect user to dashboard if he tries to access invalid url */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Route>
      </Routes>
    );
};

export default App;
