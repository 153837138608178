import { Avatar, Typography, Button, IconButton } from "@mui/material";
import { t } from "i18next";
import BoxDX from "../layout/boxdx";
import GridDX from "../layout/griddx";
import { useRef } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";

const UploadBoxDX = (props: any) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: any) => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  const handleDelete = async () => {
    props.setImage(null);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    props.setImage(event.target.files[0]);
  };

  const onChangeHandler = async (event: any) => {
    console.log(event.target.files[0]);
    props.setImage(event.target.files[0]);
  };

  return (
    <>
      <BoxDX
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        component="fieldset"
        sx={{
          border: "1px solid #c6c6c6",
          borderRadius: 1,
          display: "flex",
          alignItems: "center",
          height: "200px",
        }}
      >
        <legend style={{ color: "#808080", fontSize: 14 }}>
          {props.label}
        </legend>
        <GridDX item xs={12} justifyContent="center" direction="column">
          {props.image ? (
            <GridDX item xs={12} justifyContent="center">
              <BoxDX
                height="150px"
                width="100%"
                component="img"
                alt="picture"
                src={URL.createObjectURL(props.image)}
              />
            </GridDX>
          ) : (
            <>
              <GridDX item xs={12} justifyContent="center">
                <Avatar
                  variant="square"
                  style={{
                    display: "flex",
                    fontSize: "25px",
                  }}
                >
                  <AddPhotoAlternateIcon />
                </Avatar>
              </GridDX>
              <GridDX item xs={12} justifyContent="center" direction="column">
                <Typography
                  sx={{
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  {t("Drag and Drop Image here or")}
                </Typography>
                <Button variant="text" onClick={handleClick}>
                  {t("Browse")}
                </Button>
              </GridDX>
              <GridDX
                item
                xs={12}
                sx={{ display: "none" }}
                justifyContent="center"
              >
                <input
                  ref={inputRef}
                  accept="image/*"
                  type="file"
                  name="file"
                  style={{
                    display: "none",
                  }}
                  onChange={onChangeHandler}
                />
              </GridDX>
            </>
          )}
        </GridDX>
      </BoxDX>
      <BoxDX>
        {props.image && (
          <IconButton onClick={handleDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </BoxDX>
    </>
  );
};

export default UploadBoxDX;
