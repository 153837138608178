import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridDX from "../components/layout/griddx";
import AppBarDX from "../components/business/appbar/mainappbardx";
import NotificationBarDX from "../components/layout/notificationbardx";
import BoxDX from "../components/layout/boxdx";
import MiniDrawer from "../components/business/menu/collapsemenu";
import UnitsAppBar from "../components/business/appbar/units_appbar";

const UnitsMainTemplate = () => {
  const { i18n } = useTranslation();

  return (
    <BoxDX
      sx={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#FAFBFF",
        display: "flex",
      }}
      dir={i18n.dir()}
    >
      {/* <AppBarDX /> */}

      <GridDX
        container
        sx={{
          width: "100%",
          // marginTop: 73,
        }}
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <GridDX item xs={12} sx={{ height: "100%" }}>
          <BoxDX
            sx={{
              display: { xs: "none", md: "flex" },
              overflow: "no-scroll",
              backgroundColor: "#fff",
            }}
          > 
            <MiniDrawer />
          </BoxDX>
          <BoxDX
            sx={{
              width: "100%",
              height: "calc(100vh)",
              overflowY: "scroll",
              overflowX: "auto",
            }}
          >
            <UnitsAppBar />
            <NotificationBarDX />
            <div
              style={{
                marginTop: 60,
                padding: "16px",
                marginLeft: "16px",
              }}
            >
              <Outlet />
            </div>
          </BoxDX>
        </GridDX>
      </GridDX>
    </BoxDX>
  );
};

export default UnitsMainTemplate;
