import { useEffect, useState } from "react";
import { t } from "i18next";
import GridDX from "../layout/griddx";
import AddEditEmbeddedModal from "../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../context/notificationcontext";
import { useAuthContext } from "../../context/authcontext";
import useCustomerAddressService from "../../shared/services/customeraddressservice";
import useCityService from "../../shared/services/cityservice";
import SelectListDX from "../controls/selectlistdx";
import Addresspicker from "../business/addresspicker";
import Loading from "../layout/loading";

const CustomerAddressForm = (props: any) => {
  const { id, handleClose } = props;
  const { getCustomerAddressById, addCustomerAddress, updateCustomerAddress } =
    useCustomerAddressService();
  const { getCities } = useCityService();
  const { userData } = useAuthContext();
  const { setInfo, setError } = useNotificationContext();
  const defaultData = {
    cityId: "",
    customerId: userData?.customerId,
    streetAddress: "",
    locationCoord: "",
  };
  const defaultLocation = {
    streetAddress: "",
    lat: 24.7136,
    lng: 46.6753,
  };

  const [data, setData] = useState<any>(defaultData);
  const [cities, setCities] = useState<any>([]);
  const [location, setLocation] = useState(defaultLocation);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getCustomerAddressById(id)
        .then((res) => {
          const coord = res.locationCoord.split(",");
          setData(res);
          setLocation({
            streetAddress: res.streetAddress,
            lat: parseFloat(coord[0]),
            lng: parseFloat(coord[1]),
          });
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    getCities().then((cities) =>
      setCities(
        cities.map((city: any) => ({ text: city.cityName, value: city.cityId }))
      )
    );
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.cityId) newErrors["city"] = "City is required";
    if (!location.streetAddress)
      newErrors["streetAddress"] = "Street Address is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const address = {
        ...data,
        streetAddress: location.streetAddress,
        locationCoord: `${location.lat},${location.lng}`,
      };
      const operation = id
        ? updateCustomerAddress(address)
        : addCustomerAddress(address);
      operation
        .then(() => {
          setInfo(
            id
              ? t("Customer Address updated successfully")
              : t("Customer Address added successfully")
          );
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Customer Address")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX container columnSpacing={1} rowSpacing={2}>
        {isLoading ? (
          <Loading sx={{ width: "100%", height: "100%" }} />
        ) : (
          <>
            <GridDX item xs={12} md={6}>
              <SelectListDX
                label={t("City")}
                items={cities}
                name="cityId"
                value={data.cityId}
                onChange={handleInputChange}
                error={errors["city"]}
              />
            </GridDX>
            <GridDX item xs={12}>
              <Addresspicker
                location={location}
                setLocation={setLocation}
                onAddressChange={setIsChanged}
                emptyAddressError={errors["streetAddress"]}
              />
            </GridDX>
          </>
        )}
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default CustomerAddressForm;
