import BoxDX from "../layout/boxdx";

const ItemBox = (props: any) => {
  return (
    <BoxDX
      sx={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 0px 13px 1px #00000008",
        mt: 4,
        p: 2,
        width: "100%",
      }}
    >
      {props.children}
    </BoxDX>
  );
};

export default ItemBox;
