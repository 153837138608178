import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import { useOutletContext } from "react-router-dom";
import QuotationForm, {
  handlePrintQuotation,
} from "../components/units_forms/quotations/quotationform";
import {
  DATE_FORMAT,
  getTotalAmount,
  numberWithCommas,
  quotationStatusColor,
} from "../shared/globals";
import useQuotationService from "../shared/services/quotationservice";
import moment from "moment";
import TypeTranslator from "../shared/typetranslator";
import useSubscriptionPackageService from "../shared/services/subscriptionpackageservice";
import { Print } from "@mui/icons-material";

const Quotations = (props: any) => {
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { getQuotationStatusValue, quotationStatuses } = TypeTranslator();
  const { getQuotations, deleteQuotation, getQuotationById } =
    useQuotationService();
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getSubscriptionPackages } = useSubscriptionPackageService();
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [subscriptionPackages, setSubscriptionPackages] = useState<any>([]);
  const columns: GridColDef[] = [
    {
      field: "quotationNumber",
      headerName: t("Quotation") + " #",
      flex: 1,
    },
    {
      field: "preparedByUserName",
      headerName: t("Prepared By"),
      flex: 1,
    },
    {
      field: "quotationDate",
      headerName: t("Quotation Date"),
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: t("Due Date"),
      flex: 1,
    },
    {
      field: "customerName",
      headerName: t("Customer Name"),
      flex: 1,
    },

    {
      field: "status",
      headerName: t("Status"),
      flex: 1,

      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                quotationStatusColor.light[
                  quotationStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ] || "white",
              color: `${
                quotationStatusColor.regular[
                  quotationStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "netAmount",
      headerName: t("Net Amount"),
      flex: 1,
      valueFormatter: ({ value }) => numberWithCommas(value),
      align: "right",
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getQuotations()
      .then((response) => {
        const updatedRows = response.map((quotation: any) => ({
          ...quotation,
          status: getQuotationStatusValue(quotation.status),
          dueDate: moment(quotation.dueDate).format(DATE_FORMAT),
          quotationDate: moment(quotation.quotationDate).format(DATE_FORMAT),
        }));
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error) => {
        setError(t("Error getting quotations"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteQuotation(id)
      .then((response) => {
        setRows(response);
        setInfo(t("Quotation archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(t("Error archiving quotation"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onEdit = (data: any) => {
    setQuotationId(data.quotationId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setQuotationId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ quotationDate }: any) => {
        return moment(quotationDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi?.filter(
      ({
        quotationNumber,
        preparedByUserId,
        quotationDate,
        dueDate,
        customerName,
        status,
        netAmount,
      }: any) =>
        quotationNumber.toLowerCase().includes(value.toLowerCase()) ||
        preparedByUserId.toLowerCase().includes(value.toLowerCase()) ||
        quotationDate.toLowerCase().includes(value.toLowerCase()) ||
        dueDate.toLowerCase().includes(value.toLowerCase()) ||
        customerName.toLowerCase().includes(value.toLowerCase()) ||
        status.toLowerCase().includes(value.toLowerCase()) ||
        netAmount.toString().toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();
    // Calculate quotation prices based on items
    return () => {
      setShowTitle(true);
      setAddRecordfn(() => {});
    };
  }, [i18n.language]);

  useEffect(() => {
    getSubscriptionPackages()
      .then((response: any) => {
        setSubscriptionPackages(
          response.map((item: any) => ({ text: item.name, value: item }))
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);
  const handleOnPrint = (data: any) => {
    setIsLoading(true);
    getQuotationById(data.quotationId)
      .then((response) => {
        handlePrintQuotation(response, getQuotationStatusValue);
      })
      .catch((error) => {
        setError(t("Error printing quotation"));
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {showForm ? (
        <QuotationForm
          quotationId={quotationId}
          rows={rows}
          setRows={setRows}
          handleClose={onClose}
          subscriptionPackages={subscriptionPackages}
        />
      ) : (
        <ListPageDX
          listTitle={t("Quotations")}
          name={t("Quotation")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.quotationId}
          isLoading={isLoading}
          onEdit={onEdit}
          editLabel={t("Edit")}
          onDelete={onDelete}
          deleteLabel={t("Archive")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={true}
          filterByDate={filterByDate}
          onPrint={handleOnPrint}
        />
      )}
    </>
  );
};

export default Quotations;
