import { apiCall } from "../api/api";
import useSecureAPI from "../hooks/usesecureapi";

const useSubscriptionPackageService = () => {
  const secureAPI = useSecureAPI();

  const getSubscriptionPackages = async () => {
    const response = await secureAPI.get("SubscriptionPackage");
    return response.data;
  };

  const getSubscriptionPackageById = async (id: number) => {
    const response = await secureAPI.get(`SubscriptionPackage/${id}`);
    return response.data;
  };

  const addSubscriptionPackage = async (data: any) => {
    const response = await apiCall.post("SubscriptionPackage", data);
    return response.data;
  };

  const updateSubscriptionPackage = async (id: number, data: any) => {
    const response = await secureAPI.put(`SubscriptionPackage`, data);
    return response.data;
  };

 
  const archiveSubscriptionPackage = async (id: number) => {
    const response = await secureAPI.put(`SubscriptionPackage/archive/${id}`);
    return response.data;
  }
  return {
    getSubscriptionPackages,
    getSubscriptionPackageById,
    addSubscriptionPackage,
    updateSubscriptionPackage,
    archiveSubscriptionPackage
  };
};

export default useSubscriptionPackageService;
