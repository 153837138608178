import { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ActionDropDownMenuDx from "../controls/dropdownmenudx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DataGridDX = (props: any) => {
  const { t } = useTranslation();
  const {
    customActions,
    customFilters,
    filterByStatus,
    filterByType,
    hideColumnsOption,
    rowsPerPage,
  } = props;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage || 10,
    });
  }, [rowsPerPage]);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  function CustomToolbar() {
    return hideColumnsOption ||
      customActions ||
      customFilters ||
      filterByStatus ||
      filterByType ? (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        {hideColumnsOption && (
          <GridToolbarColumnsButton
            sx={{ fontSize: 14 }}
            startIcon={<></>}
            endIcon={<KeyboardArrowDownIcon style={{ fontSize: "24px" }} />}
          />
        )}
        {customActions && rowSelectionModel.length > 0 && (
          <ActionDropDownMenuDx
            rowSelectionModel={rowSelectionModel}
            label={t("Actions")}
            actions={customActions}
          />
        )}
        {customFilters && (
          <ActionDropDownMenuDx
            rowSelectionModel={rowSelectionModel}
            label={t("Filter")}
            actions={customFilters}
          />
        )}
        {filterByStatus && (
          <ActionDropDownMenuDx
            rowSelectionModel={rowSelectionModel}
            label={t("Filter By Status")}
            actions={filterByStatus}
          />
        )}
        {filterByType && (
          <ActionDropDownMenuDx
            rowSelectionModel={rowSelectionModel}
            label={t("Filter By Type")}
            actions={filterByType}
          />
        )}
      </GridToolbarContainer>
    ) : null;
  }

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        {t("No rows to display")}
      </div>
    );
  };

  return (
    <DataGrid
      apiRef={props.apiRef}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        toolbar: CustomToolbar,
      }}
      disableColumnFilter
      disableColumnSelector={!hideColumnsOption}
      disableDensitySelector
      paginationModel={paginationModel}
      pageSizeOptions={[5, 10, 20, 25]}
      onPaginationModelChange={setPaginationModel}
      localeText={{
        toolbarColumns: t("Columns"),
        columnsPanelTextFieldLabel: t("Find column"),
        columnsPanelTextFieldPlaceholder: t("Column title"),
        columnsPanelDragIconLabel: t("Reorder column"),
        columnsPanelShowAllButton: t("Show all"),
        columnsPanelHideAllButton: t("Hide all"),
        columnMenuLabel: t("Menu"),
        columnMenuShowColumns: t("Show columns"),
        columnMenuManageColumns: t("Manage columns"),
        columnMenuHideColumn: t("Hide column"),
        columnMenuUnsort: t("Unsort"),
        MuiTablePagination: {
          labelRowsPerPage: t("Rows per page:"),
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}–${to} ${t("of")} ${
              count !== -1 ? count : `more than ${to}`
            }`;
          },
        },

        // columnMenuSortAsc: (colDef: { field: string; }) => colDef.field === 'quickNumber' ? "quick number" : "not quicknumber",
        // columnMenuSortDesc: (colDef: { field: string; }) => colDef.field === 'quickNumber' ? "quicknumber" : "not quicknumber",

        columnMenuSortAsc: t("Ascending order"),
        columnMenuSortDesc: t("Descending order"),
        footerTotalRows: t("Total Rows"),
        footerRowSelected: (count) =>
          count !== 1
            ? `${count.toLocaleString()} ${t("rows selected")}`
            : `${count.toLocaleString()} ${t("row selected")}`,
      }}
      getRowHeight={() => props.rowHeight ?? "auto"}
      checkboxSelection={props.checkboxSelection ?? true}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: "#FFFFFF",
        minHeight: 250,
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          minHeight: 250,
        },
        "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
          width: "100%",
          backgroundColor: "#F6F7F8",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          width: "100%",
        },
      }}
    />
  );
};

export default DataGridDX;
