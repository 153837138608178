import useSecureAPI from "../hooks/usesecureapi";

const useMovementRequestService = () => {
  const secureAPI = useSecureAPI();

  const getMovementRequests = async () => {
    const response = await secureAPI.get("MovementRequest");
    return response.data;
  };

  const getMovementRequestById = async (id: number) => {
    const response = await secureAPI.get(`MovementRequest/${id}`);
    return response.data;
  };

  const addMovementRequest = async (data: any) => {
    const response = await secureAPI.post("MovementRequest", data);
    return response.data;
  };

  const updateMovementRequest = async (id: number, data: any) => {
    const response = await secureAPI.put(`MovementRequest`, data);
    return response.data;
  };

  const deleteMovementRequest = async (id: number) => {
    const response = await secureAPI.delete(`MovementRequest/${id}`);
    return response.data;
  };

  return {
    getMovementRequests,
    getMovementRequestById,
    addMovementRequest,
    updateMovementRequest,
    deleteMovementRequest,
  };
};

export default useMovementRequestService;
