import { CardContent } from "@mui/material";
import CardDX from "./carddx";
import BoxDX from "./boxdx";
import GridDX from "./griddx";
import appLogo from "../../assets/app_logo.png";

const LoginBox = (props: any) => {
  return (
    <CardDX
      sx={{
        width: { xs: 350, sm: 486 },
        margin: "auto",
        borderColor: "none",
        ...props.sx,
      }}
    >
      <CardContent sx={{ px: { xs: "24px", sm: "60px" } }}>
        <GridDX item xs={12} justifyContent="center" mb={4}>
          <BoxDX>
            <img src={appLogo} height={125} alt="UNITS" />
          </BoxDX>
        </GridDX>
        {props.children}
      </CardContent>
    </CardDX>
  );
};

export default LoginBox;
