import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import { useNotificationContext } from "../../../context/notificationcontext";
import { outboundRequestSkuStatus, packageTypes } from "../../../shared/globals";
import SelectListDX from "../../controls/selectlistdx";
import { useAuthContext } from "../../../context/authcontext";
import { AddOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import SkuForm from "../sku/skuform";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";

const OutboundRequestItemForm = (props: any) => {
  const { userData } = useAuthContext();
  const {
    requestItemId,
    requestData,
    setRequestData,
    handleClose,
    skuDropdownData,
    getSkuDropDownData,
    skus,
  } = props;
  const { t } = useTranslation();
  const defaultValues = {
    item_id: requestData.outboundRequestSkus.length + 1,
    quantity: 1,
    skuId: null,
    packagingTypeId: null,
    receiptNotes: null,
    quantityReceived: null,
    status: null,
    expiryDate: null,
  };
  const [itemData, setItemData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { setInfo } = useNotificationContext();

  useEffect(() => {
    getData();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!itemData.skuId) newErrors.package = t("Sku is required");
    if (!itemData.quantity) newErrors.quantity = t("Quantity is required");
    if (itemData.quantity <= 0)
      newErrors.quantity = t("Quantity must be greater than 0");
    if (!itemData.packagingTypeId)
      newErrors.packagingTypeId = t("Package type is required");
    if (isSelectedSkuPerishable && !itemData.expiryDate)
      newErrors.expiryDate = t("Expiry date is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = async () => {
    requestItemId &&
      setItemData(
        requestData.outboundRequestSkus.find(
          (item: any) => item.item_id == requestItemId
        )
      );
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setItemData({
      ...itemData,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setItemData({
      ...itemData,
      [name]: value,
    });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!requestItemId) {
      setItemData({ ...itemData, status: userData.userType == "S" ? 2 : 1 });
    }
  }, [requestItemId]);

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const newItems = [...requestData.outboundRequestSkus];
      const index = newItems.findIndex(
        (item: any) => item.item_id === itemData.item_id
      );
      if (index > -1) {
        newItems[index] = itemData;
        setInfo("Item updated successfully");
      } else {
        newItems.push({
          ...itemData,
          outbound_request_id: requestData.outboundRequestId,
          item_id: newItems.length + 1,
        });
        setInfo("Item added successfully");
      }
      const updatedData = {
        ...requestData,
        outboundRequestSkus: newItems,
      };
      setRequestData(updatedData);
      setIsChanged(false);
      setIsLoading(false);
      handleClose();
      props.setIsChanged(true);
    }
  };

  const [showSkuForm, setShowskuform] = useState(false);
  const [isSelectedSkuPerishable, setIsSelectedSkuPerishable] = useState(false);

  useEffect(() => {
    if (itemData.skuId) {
      const selectedSku = skus.find((s: any) => s.skuId === itemData.skuId);
      setIsSelectedSkuPerishable(selectedSku?.isPerishable);
    }
  }, [itemData.skuId]);
  return (
    <>
      {showSkuForm ? (
        <Dialog open={true} fullWidth maxWidth="xl">
          <DialogContent sx={{ py: 0 }}>
            <SkuForm
              handleClose={(props: any) => {
                if (props?.skuId) {
                  getSkuDropDownData();
                  // userData.userType == "S"  set status to 2 or if userType == "C" set status to 1
                  skuDropdownData.push({
                    value: props?.skuId,
                    text: props?.name,
                  });
                  setItemData({
                    ...itemData,
                    skuId: props?.skuId,
                    status: userData.userType == "S" ? 2 : 1,
                  });
                  setIsChanged(true);
                }

                setShowskuform(false);
              }}
              customerId={requestData.customerId}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <AddEditModalDX
          open={true}
          handleClose={handleClose}
          isSaving={isLoading}
          isChanged={isChanged}
          title={t("Item")}
          maxWidth="sm"
          onSaveClick={onSave}
          onYesClick={handleClose}
        >
          {isLoading && (
            <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
          )}

          <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
            <GridDX item xs={10}>
              <SelectListDX
                // disable if status is 8 approved or if there is an outboundRequestSkuId (meaning it is already saved)
                // 5 is modified
                disabled={
                  (requestData.status == 3 && itemData.outboundRequestSkuId) ||
                  requestData.status == 5
                }
                label={t("Sku")}
                items={skuDropdownData}
                value={itemData.skuId}
                onChange={handleInputChange}
                name="skuId"
                error={errors["skuId"]}
                InputLabelProps={{ shrink: !!itemData.skuId }}
              />
            </GridDX>
            <GridDX item xs={2} justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: "white", height: "90%" }}
                onClick={() => setShowskuform(true)}
              >
                <AddOutlined />
              </Button>
            </GridDX>
            {isSelectedSkuPerishable && (
              <GridDX item xs={12}>
                <UnitsDatePicker
                 disabled={
                  (requestData.status == 3 && itemData.outboundRequestSkuId) ||
                  requestData.status == 5
                }
                  label={t("Expiry Date")}
                  name="expiryDate"
                  value={moment(itemData.expiryDate)}
                  onChange={(value: any) => handleDateChange(value, "expiryDate")}
                  minDate={moment()}
                  error={errors["expiryDate"]}
                />
              </GridDX>
            )}
            <GridDX item xs={12}>
              <SelectListDX
                // disable if status is 8 approved or if there is an outboundRequestSkuId (meaning it is already saved)
                disabled={
                  (requestData.status == 3 && itemData.outboundRequestSkuId) ||
                  requestData.status == 5
                }
                label={t("Package Type")}
                items={packageTypes}
                value={itemData.packagingTypeId}
                onChange={handleInputChange}
                name="packagingTypeId"
                error={errors["packagingTypeId"]}
                InputLabelProps={{ shrink: !!itemData.packagingTypeId }}
              />
            </GridDX>
            <GridDX item xs={12}>
              <TextFieldDX
                // disable if status is 8 approved or if there is an outboundRequestSkuId (meaning it is already saved)
                disabled={
                  (requestData.status == 3 && itemData.outboundRequestSkuId) ||
                  requestData.status == 5
                }
                type="number"
                InputProps={{ inputProps: { min: 1, max: 10 } }}
                label={t("Quantity")}
                name="quantity"
                value={itemData.quantity}
                onChange={handleInputChange}
                error={errors["quantity"]}
              />
            </GridDX>

            {/* //quantityReceived */}
            {((userData.userType == "S" && requestData.status == 3) ||
              requestData.status == 5) && (
              <GridDX item xs={12}>
                <TextFieldDX
                  // 5 is modified
                  disabled={requestData.status == 5}
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  label={t("Received Quantity")}
                  name="quantityReceived"
                  value={itemData.quantityReceived}
                  onChange={handleInputChange}
                />
              </GridDX>
            )}
            {(userData.userType == "S" && requestData.status == 3) ||
            requestData.status == 5 ? (
              <GridDX item xs={12}>
                <SelectListDX
                  label={t("Status")}
                  // skip 1 and 2
                  items={outboundRequestSkuStatus.filter((e) => e.value > 2)}
                  value={itemData.status}
                  onChange={handleInputChange}
                  name="status"
                  error={errors["status"]}
                  // when status is undefined or 1 or 2, shrink the label
                  InputLabelProps={{ shrink: itemData.status > 2 }}
                />
              </GridDX>
            ) : (
              <GridDX item xs={12}>
                <SelectListDX
                  // disable if status is 1 or 2
                  disabled={itemData.status == 1 || itemData.status == 2}
                  label={t("Status")}
                  items={outboundRequestSkuStatus}
                  value={itemData.status}
                  onChange={handleInputChange}
                  name="status"
                  error={errors["status"]}
                  InputLabelProps={{ shrink: !!itemData.status }}
                />
              </GridDX>
            )}
            {((userData.userType == "S" && requestData.status == 3) ||
              requestData.status == 5) && (
              // receiptNotes
              <GridDX item xs={12}>
                <TextFieldDX
                  disabled={requestData.status == 5}
                  label={t("Receipt Notes")}
                  name="receiptNotes"
                  value={itemData.receiptNotes}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                />
              </GridDX>
            )}
          </GridDX>
        </AddEditModalDX>
      )}
    </>
  );
};

export default OutboundRequestItemForm;
