import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import { useNotificationContext } from "../../context/notificationcontext";

import ListPageDX from "../../components/business/listpagedx";
import WarehouseForm from "../../components/pages/warehouse/warehousedetails";

const WareHouse = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 250,
    },
    {
      field: "streetAddress",
      headerName: `${t("Address")}`,
      flex: 1,
    },
    {
      field: "cityName",
      headerName: `${t("City")}`,
      headerAlign: "center",
      align: "center",
      minWidth: 250,
    },
  ];

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  const getData = () => {
    const data = [
      {
        warehouseId: 1,
        name: "Warehouse 1",
        streetAddress: "Somewhere",
        cityName: "Riyadh",
      },
    ];

    setIsLoading(true);
    setDataFromApi(data);
    setRows(data);
    setIsLoading(false);

    // getSubscriptionPackages()
    //   .then((data) => {
    //     setDataFromApi(data);
    //     setRows(data);
    //   })
    //   .catch((err) => setError(err))
    //   .finally(() => setIsLoading(false));
  };

  const onEdit = (data: any) => {
    setSelectedWarehouse(data);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onDelete = (id: number) => {
    // setIsLoading(true);
    // archiveSubscriptionPackage(id)
    //   .then(() => {
    //     setInfo(t("Package deleted successfully"));
    //     getData();
    //   })
    //   .catch((err) => setError(err))
    //   .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setSelectedWarehouse(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.description.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  return (
    <>
      {showForm ? (
        <WarehouseForm packageData={selectedWarehouse} handleClose={onClose} />
      ) : (
        <ListPageDX
          listTitle={t("Warehouses")}
          name={t("Warehouses")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.warehouseId}
          isLoading={isLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          editLabel={t("Preview")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={false}
          showSelect={false}
        />
      )}
    </>
  );
};

export default WareHouse;
