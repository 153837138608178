import useSecureAPI from "../hooks/usesecureapi";

const useOutboundRequestService = () => {
  const secureAPI = useSecureAPI();

  
  const getOutboundRequests = async () => {
    const response = await secureAPI.get("OutboundRequest");
    return response.data;
  };

  const getOutboundRequestById = async (id: number) => {
    const response = await secureAPI.get(`OutboundRequest/${id}`);
    return response.data;
  };

  const addOutboundRequest = async (data: any) => {
    const response = await secureAPI.post("OutboundRequest", data);
    return response.data;
  };

  const updateOutboundRequest = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest`, data);
    return response.data;
  };

  const archiveOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/archive/${id}`);
    return response.data;
  };

  const acceptOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/accept/${id}`);
    return response.data;
  };
  const declineOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/decline/${id}`);
    return response.data;
  };
 
  const processOutboundRequest = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/process`, data);
    return response.data;
  };


  const rejectModifications = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/modificationreject/${id}`);
    return response.data;
  }
  const approveModifications = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/modificationapprove/${id}`);
    return response.data;
  }


  return {
    getOutboundRequests,
    getOutboundRequestById,
    addOutboundRequest,
    updateOutboundRequest,
    archiveOutboundRequest,
    acceptOutboundRequest,
    declineOutboundRequest,
    processOutboundRequest,
    rejectModifications,
    approveModifications
  
  };
};

export default useOutboundRequestService;



