import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridDX from "../components/layout/griddx";
import NotificationBarDX from "../components/layout/notificationbardx";
const AccountTemplate = () => {
  const { i18n } = useTranslation();
  return (
    <GridDX
      container
      sx={{
        height: "100vh",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      dir={i18n.dir()}
    >
      <GridDX
        item
        xs={12}
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <NotificationBarDX />
        <Outlet />
      </GridDX>
    </GridDX>
  );
};

export default AccountTemplate;
