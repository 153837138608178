import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import LeadForm from "../components/units_forms/leadform";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { DATE_FORMAT, leadStatusColor } from "../shared/globals";
import useLeadService from "../shared/services/leadservice";
import TypeTranslator from "../shared/typetranslator";

const Leads = (props: any) => {
  const {
    getStorageTypeValue,
    getLeadRequestTypeValue,
    getLeadRequestStatusValue,
    leadRequestStatuses,
    getRentalPeriodValue,
  } = TypeTranslator();

  const { quotationRows, setQuotationRows } = props;
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getLeads, archiveLead } = useLeadService();
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: `${t("Lead Date")}`,
      flex: 1,
    },
    {
      field: "fullname",
      headerName: `${t("Full Name")}`,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: `${t("Phone Number")}`,
      flex: 1,
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      flex: 1,
    },
    {
      field: "requiredStorage",
      headerName: `${t("Required Storage")}`,
      flex: 1,
    },
    {
      field: "rentalPeriodMonths",
      headerName: `${t("Rental Period(Months)")}`,
      flex: 1,
    },
    {
      field: "storageTypeId",
      headerName: `${t("Storage Type")}`,
      flex: 1,
    },
    {
      field: "requestType",
      headerName: `${t("Request Type")}`,
      flex: 1,
    },
    {
      field: "requestStatus",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                leadStatusColor.light[
                  leadRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ] || "white",
              color: `${
                leadStatusColor.regular[
                  leadRequestStatuses.find(
                    (e: any) => e?.en_value == params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getLeads()
      .then((data) => {
        console.log("data", data);
        const formatedData = data.map((item: any) => {
          return {
            ...item,
            requestDate: moment(item.requestDate).format(DATE_FORMAT),
            storageTypeId: getStorageTypeValue(item.storageTypeId),
            requestType: getLeadRequestTypeValue(item.requestType),
            requestStatus: getLeadRequestStatusValue(item.requestStatus),
            rentalPeriodMonths: getRentalPeriodValue(item.rentalPeriodMonths),
          };
        });

        setRows(formatedData);
        setDataFromApi(formatedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveLead(id)
      .then(() => {
        setInfo(t("Lead archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const [showForm, setShowForm] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const onEdit = (data: any) => {
    setEditData(data);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowAddButton(true);
    setShowForm(false);
    setEditData(null);
    if (refreshPage) getData();
  };
  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        fullname,
        phoneNumber,
        email,
        requiredStorage,
        rentalPeriodMonths,
        storageTypeId,
        requestType,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          fullname.toLowerCase().includes(value.toLowerCase()) ||
          phoneNumber.toLowerCase().includes(value.toLowerCase()) ||
          email.toLowerCase().includes(value.toLowerCase()) ||
          requiredStorage.toLowerCase().includes(value.toLowerCase()) ||
          rentalPeriodMonths
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          getStorageTypeValue(storageTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          getLeadRequestTypeValue(requestType)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(() => {});
    };
  }, [i18n.language]);
  return (
    <>
      {showForm ? (
        <LeadForm
          data={editData}
          handleClose={onClose}
          quotationRows={quotationRows}
          setQuotationRows={setQuotationRows}
          leadId={editData?.requestForQuoteId}
        />
      ) : (
        <ListPageDX
          listTitle={t("Leads")}
          name={t("Lead")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.requestForQuoteId}
          isLoading={isLoading}
          onEdit={onEdit}
          editLabel={t("Preview")}
          onDelete={onDelete}
          deleteLabel={t("Archive")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={true}
          filterByDate={filterByDate}
        />
      )}
    </>
  );
};

export default Leads;
