import { useEffect, useState } from "react";
import { t } from "i18next";
import TextFieldDX from "../../controls/textfielddx";
import UploadBoxDX from "../../controls/uploadboxdx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import useSKUService from "../../../shared/services/skuservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import Loading from "../../layout/loading";
import CheckBoxDX from "../../controls/checkboxdx";

const SkuForm = (props: any) => {
  const { id, handleClose } = props;
  const { getSKUById, addSKU, updateSKU } = useSKUService();
  const { userData } = useAuthContext();
  const { setInfo, setError } = useNotificationContext();
  const defaultData = {
    code: "",
    name: "",
    specification: "",
    isPerishable: false,
    picture: null,
  };

  const [data, setData] = useState<any>(defaultData);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.code) newErrors["code"] = "Code is required";
    if (!data.name) newErrors["name"] = "Name is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);
      const formData = new FormData();
      formData.append("code", data.code);
      formData.append("name", data.name);
      formData.append("customerId", userData.customerId || props.customerId);
      formData.append("specification", data.specification);
      formData.append("isPerishable", data.isPerishable);
      formData.append("picture", data.picture);

      const operation = id ? updateSKU(formData) : addSKU(formData);
      operation
        .then((response) => {
          setInfo(
            id ? t("SKU updated successfully") : t("SKU added successfully")
          );
          if (props.customerId) {
            handleClose({ skuId: response?.skuId, name: response?.name });
          } else {
            handleClose(true);
          }
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSKUById(id)
        .then((res) => {
          if (res.pictureDirLocation) {
            fetch(res.pictureDirLocation, {
              headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
            }).then(async (file) => {
              const blob = await file.blob();
              setData({
                ...res,
                picture: blob,
              });
            });
          } else setData(res);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("SKU Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading />}
      <GridDX container columnSpacing={1} rowSpacing={2}>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("SKU Code")}
            name="code"
            value={data?.code}
            onChange={handleInputChange}
            error={errors["code"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Specification")}
            name="specification"
            value={data?.specification}
            onChange={handleInputChange}
            multiline
            minRows={2}
            maxRows={4}
          />
        </GridDX>
        <GridDX item xs={6}>
          <CheckBoxDX
            name={"isPerishable"}
            label={t("Perishable")}
            checked={data?.isPerishable}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} sx={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <UploadBoxDX
              sx={{ width: "270px", height: "250px" }}
              label={t("Picture")}
              image={data.picture}
              setImage={(newImage: any) => {
                newImage !== undefined &&
                  setData({
                    ...data,
                    picture: newImage,
                  });
              }}
            />
          </div>
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default SkuForm;
