import { Typography, Divider } from "@mui/material";
import BoxDX from "./boxdx";

const CountBoxDX = (props: any) => {
  const { title, icon, count1, label1, count2, label2  } = props;

  return(
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p:1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX display="flex" flexDirection="row" width="100%">
        {icon}
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1}}>{title}</Typography>
      </BoxDX>
      <Divider sx={{ my: 1 }}/>
      <BoxDX display="flex" flexDirection="row" width="100%">
        {count1 && count2 ? (
          <>
            <BoxDX display="flex" flexDirection="column" sx={{ minWidth: "5%" }}>
              <Typography fontSize={22} fontWeight="bold" textAlign={"start"} sx={{ height: 30 }}>{count1}</Typography>
              <Typography fontSize={22} fontWeight="bold" textAlign={"start"} sx={{ height: 30 }}>{count2}</Typography>
            </BoxDX>  
            <BoxDX display="flex" flexDirection="column" width="100%">
              <Typography fontSize={18} sx={{ mx: 2, height: 30, alignContent: "end" }}>{label1}</Typography>
              <Typography fontSize={18} sx={{ mx: 2, height: 30, alignContent: "end" }}>{label2}</Typography>
            </BoxDX>
          </>
        ) : <Typography fontSize={26} fontWeight="bold" textAlign={"start"} sx={{ height: 30 }}>{count1}</Typography>
      }
      </BoxDX>
    </BoxDX>
  );
};

export default CountBoxDX;