import { Typography } from "@mui/material";
import BoxDX from "./boxdx";
import StaticDataGridDX from "./staticdatagriddx";
import { useGridApiRef } from "@mui/x-data-grid";

const GridBoxDX = (props: any) => {
  const { title, rows, columns, getRowId  } = props;
  const apiRef = useGridApiRef();

  return(
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p:1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX display="flex" flexDirection="row" width="100%">
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1}}>{title}</Typography>
      </BoxDX>
      <StaticDataGridDX
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        apiRef={apiRef}
        sx={{ mx: 1, mt: 3 }}
      />
    </BoxDX>
  );
};

export default GridBoxDX;