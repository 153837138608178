import useSecureAPI from "../hooks/usesecureapi";

const useCityService = () => {
  const secureAPI = useSecureAPI();

  const getCities = async () => {
    const response = await secureAPI.get("City");
    return response.data;
  };

  const getCityById = async (id: number) => {
    const response = await secureAPI.get(`City/${id}`);
    return response.data;
  };

  const addCity = async (data: any) => {
    const response = await secureAPI.post("City", data);
    return response.data;
  };

  const updateCity = async (data: any) => {
    const response = await secureAPI.put(`City`, data);
    return response.data;
  };

  const deleteCity = async (id: number) => {
    const response = await secureAPI.delete(`City/${id}`);
    return response.data;
  };

  return {
    getCities,
    getCityById,
    addCity,
    updateCity,
    deleteCity,
  };
};

export default useCityService;
