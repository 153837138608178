import {
  WarehouseOutlined,
  Inventory2Outlined,
  GroupsOutlined,
  ContentPasteOutlined,
  LocalShippingOutlined,
  CalendarViewMonthOutlined,
  CategoryOutlined,
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import CustomBarChart from "../../../charts/CustomBarChart";
import CountBoxDX from "../../../layout/countboxdx";
import GridBoxDX from "../../../layout/gridboxdx";
import GridDX from "../../../layout/griddx";

const GeneralOverview = () => {
  const { t } = useTranslation();
  const pData = [30, 21, 66, 45, 80, 90, 23, 10, 54, 95, 88, 71];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      maxWidth: 300,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
    },
    {
      field: "date",
      headerName: t("Expiration Date"),
      width: 150,
    },
  ];

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Storage")}
          icon={<WarehouseOutlined color="primary" fontSize="large" />}
          count1={2}
          label1={t("Total Occupied")}
          count2={8}
          label2={t("Free Capacity")}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Goods Movement")}
          icon={<Inventory2Outlined color="primary" fontSize="large" />}
          count1={120}
          label1={t("Goods")}
          count2={56}
          label2={t("CBM")}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Customers")}
          icon={<GroupsOutlined color="primary" fontSize="large" />}
          count1={217}
          label1={t("Total")}
          count2={132}
          label2={t("Active")}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Orders")}
          icon={<ContentPasteOutlined color="primary" fontSize="large" />}
          count1={12}
          label1={t("Inbounds")}
          count2={6}
          label2={t("Outbounds")}
        />
      </GridDX>
      <GridDX item xs={9} mt={1}>
        <CustomBarChart
          title={t("Space Utilization")}
          pData={pData}
          xLabels={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          isLoading={false}
          height={350}
          filterRange={false}
          warehouseFilter={true}
          colors={["#AD70FF"]}
        />
      </GridDX>
      <GridDX container xs={3} rowSpacing={2} mt={1} pl={1}>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Delivered")}
            icon={<LocalShippingOutlined color="primary" fontSize="large" />}
            count1={5}
            label1={t("Inbounds")}
            count2={3}
            label2={t("Outbounds")}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Pallets Available")}
            icon={
              <CalendarViewMonthOutlined color="primary" fontSize="large" />
            }
            count1={33}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Items Expiring")}
            icon={<CategoryOutlined color="primary" fontSize="large" />}
            count1={16}
          />
        </GridDX>
      </GridDX>
      <GridDX item xs={12}>
        <GridBoxDX
          title={t("Contracts Expiring in 30 Days")}
          rows={[]}
          columns={columns}
          getRowId={(row: any) => row.id}
        />
      </GridDX>
    </GridDX>
  );
};

export default GeneralOverview;
