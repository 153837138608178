import { TextField, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DATE_FORMAT } from "../../shared/globals";

const UnitsDatePicker = (props: any) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const useMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const isError = props.error ? true : false;
  const errorText = props.error ?? "";
  const helperText = props.helperText;
  const dateFormat = props.format ?? DATE_FORMAT; //DATE_FORMAT;
  const platform = navigator?.userAgent ?? "";
  const loading = props.loading ?? false;
  const fontSize = props.fontSize ?? "medium";

  const CalendarStyle =
    i18n.dir() === "rtl"
      ? {
          ...props.sx,
          "& label": {
            left: "unset",
            right: "1.75rem",
            transformOrigin: "right",
          },
          "& legend": {
            textAlign: "right",
          },
          "& .MuiFormHelperText-root": {
            textAlign: "right",
          },
          "& .MuiSelect-icon": {
            left: 0, // Change the position of the icon to the left
            right: "auto", // Reset the right property
          },
        }
      : { width: "100%", ...props.sx };
  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        value={props.value ? moment(props.value).format(dateFormat) : ""}
        variant="outlined"
        fullWidth
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
      />
    );
  else {
    return (
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={i18n.language}
      >
        <DatePicker
          {...props}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
              size: props.size,
            },
            popper: {
              popperOptions: {
                placement: "top",
              },
              sx: {
                height: "200px !important",
              },
            },
          }}
          value={props.value && moment(props.value, dateFormat)}
          format={dateFormat}
          sx={CalendarStyle}
        />
      </LocalizationProvider>
    );
  }
};

export default UnitsDatePicker;
