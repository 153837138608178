import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../components/business/listpagedx";
import { useNotificationContext } from "../context/notificationcontext";
import { useAuthContext } from "../context/authcontext";
import useCustomerAddressService from "../shared/services/customeraddressservice";
import CustomerAddressForm from "../components/units_forms/customeraddressform";

const CustomerAddress = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { userData } = useAuthContext();
  const { setInfo, setError } = useNotificationContext();
  const { getCustomerAddressForCustomer, deleteCustomerAddress } = useCustomerAddressService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [CustomerAddressId, setCustomerAddressId] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "streetAddress",
      headerName: `${t("Street Address")}`,
      flex: 1
    },
  ];

  const getData = () => {
    if (userData.userType === "S") {
      setRows([]);
      setDataFromApi([]);
      setShowAddButton(false);
    }
    else {
      setIsLoading(true);
      getCustomerAddressForCustomer(userData?.customerId)
        .then((data: any) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onEdit = (data: any) => {
    setCustomerAddressId(data.customerAddressId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteCustomerAddress(id)
      .then(() => {
        setInfo(t("Customer Address deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setCustomerAddressId(null);
    setShowAddButton(userData.userType === "S" ? false : true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.streetAddress.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    setDataFromApi(rows);
    getData();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  return (
    <>
      {showForm ? (
        <CustomerAddressForm
          id={CustomerAddressId}
          handleClose={onClose}
        />
      ) : (
        <ListPageDX
          listTitle={t("Customer Address")}
          name={t("Customer Address")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.customerAddressId}
          isLoading={isLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          editLabel={t("Preview")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={false}
          showSelect={false}
        />
      )}
    </>
  );
};

export default CustomerAddress;
