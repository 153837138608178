import useSecureAPI from "../hooks/usesecureapi";

const useQuotationService = () => {
  const secureAPI = useSecureAPI();

  const getQuotations = async () => {
    const response = await secureAPI.get("Quotation");
    return response.data;
  };

  const getQuotationById = async (id: number) => {
    const response = await secureAPI.get(`Quotation/${id}`);
    return response.data;
  };

  const addQuotation = async (data: any) => {
    const response = await secureAPI.post("Quotation", data);
    return response.data;
  };

  const updateQuotation = async (data: any) => {
    const response = await secureAPI.put(`Quotation`, data);
    return response.data;
  };

  // const deleteQuotation = async (id: number) => {
  //   const response = await secureAPI.delete(`Quotation/${id}`);
  //   return response.data;
  // };

  const deleteQuotation = async (id: number) => {
    const response = await secureAPI.put(`Quotation/archive/${id}`);
    return response.data;
  }

  return {
    getQuotations,
    getQuotationById,
    addQuotation,
    updateQuotation,
    deleteQuotation,
  };
};

export default useQuotationService;
