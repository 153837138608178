import { TextField, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const SelectListDX = (props: any) => {
  const { i18n, t } = useTranslation();

  const isError = props.error ? true : false;
  const helperText = props.helperText; // ?? props.label;
  const loading = props.loading ?? false;

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        variant={"standard"}
        fullWidth
        helperText={helperText}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{ shrink: true }}
        sx={
          i18n.dir() === "rtl"
            ? {
                ...props.sx,
                "& label": {
                  left: "unset",
                  right: "0rem",
                  transformOrigin: "right",
                },
                "& legend": {
                  textAlign: "right",
                },
                "& .MuiFormHelperText-root": {
                  textAlign: "right",
                },
              }
            : { ...props.sx }
        }
        {...props}
      />
    );
  else
    return (
      <TextField
        select
        fullWidth
        error={isError}
        helperText={props.error ?? helperText}
        onChange={props.handleChange}
        defaultValue={props.value}
        value={props.value}
        {...props}
        sx={
          i18n.dir() === "rtl"
            ? {
                ...props.sx,
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                },
                "& legend": {
                  textAlign: "right",
                },
                "& .MuiFormHelperText-root": {
                  textAlign: "right",
                },
                "& .MuiSelect-icon": {
                  left: 0, // Change the position of the icon to the left
                  right: "auto", // Reset the right property
                },
                "& .MuiSelect-select": {
                  paddingRight: "10px !important",
                  paddingLeft: "24px !important",
                  textAlign: "right",
                },
              }
            : {
                textAlign: i18n.dir() === "rtl" ? "right" : "left",
                ...props.sx,
              }
        }
      >
        {props.items && props.items.length != 0 ? (
          props.items.map((item: any, index: number) => (
            <MenuItem
              key={`${props.text}-${index}`}
              value={item.value}
              selected={props.value === item.value ? true : false}
              sx={{
                textAlign: i18n.dir() === "rtl" ? "right" : "left",
                justifyContent:
                  i18n.dir() === "rtl" ? "flex-end" : "flex-start",
              }}
            >
              {item.text}
            </MenuItem>
          ))
        ) : (
          <p
            style={{
              padding: "0 8px",
              height: "20px",
              textAlign: i18n.dir() === "rtl" ? "right" : "initial",
            }}
          >
            {t("No Item")}
          </p>
        )}
      </TextField>
    );
};

export default SelectListDX;
