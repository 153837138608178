import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit, Save } from "@mui/icons-material";
import { useNotificationContext } from "../../../context/notificationcontext";

import BoxDX from "../../layout/boxdx";
import ItemBox from "../../units_components/itembox";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import SelectListDX from "../../controls/selectlistdx";
import DataGridDX from "../../layout/datagriddx";
import { InputAdornment } from "@mui/material";
import WarehouseLevelDetailsModal from "./warehouseleveldetailsmodal";
import TypographyDX from "../../layout/typographydx";

const WarehouseBayDetails = (props: any) => {
  const levelColumns: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("Number")}`,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "capacity",
      headerName: `${t("Capacity")}`,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "palletSize",
      headerName: `${t("Pallet Size")}`,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "numberOfPallets",
      headerName: `${t("Number Of Pallets")}`,
      minWidth: 250,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Delete")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { handleClose } = props;

  const { setInfo, setError } = useNotificationContext();
  const navigate = useNavigate();

  const [bayData, setBayData] = useState<any>({
    warehouseRowId: 1,
    warehouseBayId: 1,
    number: "B1",
    capacityInPallets: 10,
    warehouseLevels: [
      {
        warehouseLevelId: 1,
        number: "L1",
        capacity: 10,
        palletSize: 2,
        numberOfPallets: 10,
      },
    ],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showLevelDetails, setShowLevelDetails] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setBayData({
      ...bayData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const getData = () => {};

  const validateForm = () => {
    const newErrors: any = {};

    // if (!warehouseData.customerName.trim().length)
    //   newErrors["customerName"] = t("Customer Name is required");
    // if (!warehouseData.customerAddress)
    //   newErrors["customerAddress"] = t("Customer Address is required");
    // if (!warehouseData.dueDate)
    //   newErrors["dueDate"] = t("Due Date is required");
    // if (!warehouseData.customerCityId)
    //   newErrors["customerCityId"] = t("Customer City is required");
    // if (!warehouseData.warehouseId)
    //   newErrors["warehouseId"] = t("Warehouse is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...bayData,
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      // const operation = quotationId
      //   ? updateQuotation(updatedData)
      //   : addQuotation(updatedData);
      // operation
      //   .then(() => {
      //     quotationId
      //       ? setInfo(t("Quotation updated successfully"))
      //       : setInfo(t("Quotation added successfully"));
      //     handleClose(true);
      //   })
      //   .catch((error) => {
      //     setError(error);
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  const onEdit = (data: any) => {
    setShowLevelDetails(true);
  };

  const onArchive = (data: any) => {};

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      {showLevelDetails && (
        <WarehouseLevelDetailsModal
          handleClose={() => setShowLevelDetails(false)}
        />
      )}
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              label={t("Number")}
              name="number"
              value={bayData.number}
              onChange={handleInputChange}
              error={errors["number"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              label={t("Capacity")}
              name="capacityInPallets"
              value={bayData.capacityInPallets}
              onChange={handleInputChange}
              error={errors["capacityInPallets"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">Pallets</InputAdornment>
                ),
              }}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      <ItemBox>
        <TypographyDX variant="h5" sx={{ mb: 1 }}>
          {t("Levels")}
        </TypographyDX>
        <DataGridDX
          getRowId={(row: any) => row.warehouseLevelId}
          rows={bayData.warehouseLevels}
          columns={levelColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
          // customActions={customActions}
          // customFilters={customFilters}
          // filterByStatus={filterByStatus}
          // filterByType={filterByType}
          // hideColumnsOption={hideColumnsOption}
          // rowsPerPage={rowsPerPage}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseBayDetails;
