import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit, Save } from "@mui/icons-material";

import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import SelectListDX from "../../controls/selectlistdx";
import ItemBox from "../../units_components/itembox";
import TypographyDX from "../../layout/typographydx";

const WarehouseAreaDetails = (props: any) => {
  const rowColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Delete")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { handleClose } = props;

  const { setInfo, setError } = useNotificationContext();
  const navigate = useNavigate();

  const [areaData, setareaData] = useState<any>({
    warehouseId: 1,
    name: "Area 1",
    code: "A1",
    warehouseRows: [
      {
        warehouseRowId: 1,
        name: "Row 1",
      },
    ],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const onCancel = () => {
    navigate(-1);
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setareaData({
      ...areaData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    // if (!warehouseData.customerName.trim().length)
    //   newErrors["customerName"] = t("Customer Name is required");
    // if (!warehouseData.customerAddress)
    //   newErrors["customerAddress"] = t("Customer Address is required");
    // if (!warehouseData.dueDate)
    //   newErrors["dueDate"] = t("Due Date is required");
    // if (!warehouseData.customerCityId)
    //   newErrors["customerCityId"] = t("Customer City is required");
    // if (!warehouseData.warehouseId)
    //   newErrors["warehouseId"] = t("Warehouse is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...areaData,
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      // const operation = quotationId
      //   ? updateQuotation(updatedData)
      //   : addQuotation(updatedData);
      // operation
      //   .then(() => {
      //     quotationId
      //       ? setInfo(t("Quotation updated successfully"))
      //       : setInfo(t("Quotation added successfully"));
      //     handleClose(true);
      //   })
      //   .catch((error) => {
      //     setError(error);
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  const onEdit = (data: any) => {
    navigate("/warehouserowdetails");
  };

  const onArchive = (data: any) => {};

  const getData = () => {};

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={t("Name")}
              name="name"
              value={areaData.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={t("Code")}
              name="code"
              value={areaData.number}
              onChange={handleInputChange}
              error={errors["code"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              label={t("Storage Model")}
              name="storageModelTypeId"
              value={areaData.storageModelTypeId}
              items={[]}
              onChange={handleInputChange}
              error={errors["storageModelTypeId"]}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      <ItemBox>
        <TypographyDX variant="h5" sx={{ mb: 1 }}>
          {t("Rows")}
        </TypographyDX>
        <DataGridDX
          getRowId={(row: any) => row.warehouseRowId}
          rows={areaData.warehouseRows}
          columns={rowColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
          // customActions={customActions}
          // customFilters={customFilters}
          // filterByStatus={filterByStatus}
          // filterByType={filterByType}
          // hideColumnsOption={hideColumnsOption}
          // rowsPerPage={rowsPerPage}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseAreaDetails;
