import { ArrowDropDownOutlined } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import MenuItemDX from "../controls/menuitemdx";

const UnitsRangePicker = (props: any) => {
  const { t, i18n } = useTranslation();
  const { label, sx, toFilter } = props;

  const dateFormat = "MMM dd, yyyy";
  const [dateRange, setDateRange] = useState<[null | Date, null | Date]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  const InputWrapper = forwardRef<any, any>((props, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": {
              width: 270,
            },
          }}
          MenuListProps={{
            "aria-labelledby": "basic-field",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItemDX
            onClick={() => {
              const today = new Date();
              setDateRange([today, today]);
              toFilter && toFilter(today, today);
            }}
          >
            {t("Today")}
          </MenuItemDX>
          <MenuItem
            onClick={() => {
              const currentDate = new Date();
              const startingDate = new Date(currentDate);
              startingDate.setDate(
                currentDate.getDate() - currentDate.getDay()
              );
              const endingDate = new Date(currentDate);
              endingDate.setDate(
                currentDate.getDate() + (6 - currentDate.getDay())
              );
              setDateRange([startingDate, endingDate]);
              toFilter && toFilter(startingDate, endingDate);
            }}
          >
            {t("This Week")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              const currentDate = new Date();
              const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
              );
              const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
              );
              setDateRange([startDate, endDate]);
              toFilter && toFilter(startDate, endDate);
            }}
          >
            {t("This Month")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              const currentYear = new Date().getFullYear();
              const startDate = new Date(currentYear, 0, 1);
              const endDate = new Date(currentYear, 11, 31);
              setDateRange([startDate, endDate]);
              toFilter && toFilter(startDate, endDate);
            }}
          >
            {t("This Year")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              const currentDate = new Date();
              const startingDate = new Date(currentDate);
              startingDate.setDate(
                currentDate.getDate() - currentDate.getDay() - 7
              );
              const endingDate = new Date(currentDate);
              endingDate.setDate(
                currentDate.getDate() - currentDate.getDay() - 1
              );
              setDateRange([startingDate, endingDate]);
              toFilter && toFilter(startingDate, endingDate);
            }}
          >
            {t("Last Week")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              const currentDate = new Date();
              const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 1,
                1
              );
              const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                0
              );
              setDateRange([startDate, endDate]);
              toFilter && toFilter(startDate, endDate);
            }}
          >
            {t("Last Month")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              const currentYear = new Date().getFullYear();
              const startDate = new Date(currentYear - 1, 0, 1);
              const endDate = new Date(currentYear - 1, 11, 31);
              setDateRange([startDate, endDate]);
              toFilter && toFilter(startDate, endDate);
            }}
          >
            {t("Last Year")}
          </MenuItem>
          <MenuItem onClick={props.onClick}>{t("Custom Range")}</MenuItem>
          <MenuItem
            onClick={() => {
              setDateRange([null, null]);
              toFilter && toFilter(null, null);
            }}
          >
            {t("Reset")}
          </MenuItem>
        </Menu>
        <TextField
          id="basic-field"
          fullWidth
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          label={label}
          placeholder={t("Select Date Range")}
          value={props.value}
          ref={ref}
          onClick={handleClick}
          size="small"
          sx={
            i18n.dir() === "rtl"
              ? {
                  "& label": {
                    left: "unset",
                    right: "1.75rem",
                    transformOrigin: "right",
                  },
                  "& legend": {
                    textAlign: "right",
                  },
                  "& .MuiFormHelperText-root": {
                    textAlign: "right",
                  },
                  "& .MuiSelect-icon": {
                    left: 0, // Change the position of the icon to the left
                    right: "auto", // Reset the right property
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "10px !important",
                    paddingLeft: "24px !important",
                    textAlign: "right",
                  },
                  minWidth: 270,
                  ...sx,
                }
              : {
                  textAlign: i18n.dir() === "rtl" ? "right" : "left",
                  minWidth: 270,
                  sx,
                }
          }
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" disableRipple>
                  <ArrowDropDownOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  });

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        const [startDate, endDate] = update;
        toFilter && toFilter(startDate, endDate);
        setDateRange(update);
      }}
      customInput={<InputWrapper />}
      dateFormat={dateFormat}
    />
  );
};

export default UnitsRangePicker;
