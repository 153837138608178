import useSecureAPI from "../hooks/usesecureapi";

const useCustomerAddressService = () => {
  const secureAPI = useSecureAPI();

  const getCustomerAddressForCustomer = async (id: number) => {
    const response = await secureAPI.get(`CustomerAddress/forcustomer/${id}`);
    return response.data;
  };

  const getCustomerAddressById = async (id: number) => {
    const response = await secureAPI.get(`CustomerAddress/${id}`);
    return response.data;
  };

  const addCustomerAddress = async (data: any) => {
    const response = await secureAPI.post("CustomerAddress", data);
    return response.data;
  };

  const updateCustomerAddress = async (data: any) => {
    const response = await secureAPI.put(`CustomerAddress`, data);
    return response.data;
  };

  const deleteCustomerAddress = async (id: number) => {
    const response = await secureAPI.put(`CustomerAddress/archive/${id}`);
    return response.data;
  };

  return {
    getCustomerAddressForCustomer,
    getCustomerAddressById,
    addCustomerAddress,
    updateCustomerAddress,
    deleteCustomerAddress,
  };
};

export default useCustomerAddressService;
