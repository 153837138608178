import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../business/listpagedx";
import ListPageTemplate from "../../templates/listpagetemplate";
import GridDX from "../layout/griddx";
import ButtonDX from "../controls/buttondx";
import TypographyDX from "../layout/typographydx";
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNotificationContext } from "../../context/notificationcontext";
import { getTotalAmount } from "../../shared/globals";
import MovementRequestItemForm from "../units_forms/movementrequests/movementrequestitemform";

const MovementRequestItems = (props: any) => {
  const { requestData, setRequestData, getData, setIsChanged } = props;
  const [requestItemId, setRequestItemId] = useState(null);
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState<any>(requestData.items);
  const [items, setItems] = useState<any>(requestData.items);

  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();

  const columns: GridColDef[] = [
    { field: "description", headerName: t("Description"), flex: 1 },
    { field: "unit_price", headerName: t("Unit Price"), flex: 1 },
    { field: "quantity", headerName: t("Quantity"), flex: 1 },
    { field: "discount_percent", headerName: t("Discount (%)"), flex: 1 },
    { field: "discount_amount", headerName: t("Discount Amount"), flex: 1 },
    { field: "total_amount", headerName: t("Total Amount"), flex: 1 },
  ];

  const onDelete = (id: number) => {
    setRequestData((prev: any) => {
      const deletedItem = prev.items.find((item: any) => item.item_id === id);
      const updatedItems = prev.items.filter(
        (item: any) => item.item_id !== id
      );
      const updatedData = {
        ...prev,
        items: [...updatedItems, { ...deletedItem, isDeleted: true }],
        ...getTotalAmount({ ...prev, items: updatedItems } , updatedItems),
      };
      return updatedData;
    });
    setIsChanged(true);
    setInfo("Item deleted successfully");
  };

  const [showForm, setShowForm] = useState(false);
  const onEdit = (data: any) => {
    setRequestItemId(data.item_id);
    setShowForm(true);
  };

  const toCreate = () => {
    setShowForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowForm(false);
    setRequestItemId(null);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(items);
      return;
    }
    const newRows = items.filter(({ description }: any) => {
      return description.toLowerCase().includes(value.toLowerCase());
    });
    setRows(newRows);
  };

  useEffect(() => {
    setRows(requestData.items);
    setItems(requestData.items);
  }, [requestData.items]);
  return (
    <>
      {showForm && (
        <MovementRequestItemForm
          requestData={requestData}
          requestItemId={requestItemId}
          setRequestData={setRequestData}
          handleClose={onClose}
          setIsChanged={setIsChanged}
        />
      )}
      <GridDX container xs={12} >
        <GridDX item xs={12} justifyContent="space-between">
          <TypographyDX variant="h5" fontWeight="bold">{t("Items")}</TypographyDX>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "#fff", wordSpacing: 4 }}
            onClick={toCreate}
          >
            <AddOutlined /> {t("Add Item")}
          </Button>
        </GridDX>
        <GridDX item xs={12} sx={{ mb: 2 }}>
          <ListPageDX
            listTitle={t("Item")}
            name={t("Item")}
            rows={rows}
            columns={columns}
            getRowId={(row: any) => row.item_id}
            isLoading={isLoading}
            // toCreate={toCreate}
            onEdit={onEdit}
            editLabel={t("Edit")}
            onDelete={onDelete}
            exportToPDF={false}
            exportToCSV={false}
            showDatePicker={false}
            showSelect={false}
            searchAndFilter={true}
            exportDropdown={false}
            setGridFilterCriteria={setGridFilterCriteria}
          />
        </GridDX>
      </GridDX>
    </>
  );
};

export default MovementRequestItems;
